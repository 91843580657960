import React, { Component } from "react";
import { Card, Button } from "antd";

export default class RemainingCredit extends Component {
  render() {
    return (
      <Card style={{ width: "100%" }}>
        <div
          style={{
            padding: 0,
            minHeight: "48px",
            fontWeight: "bold"
          }}
        >
          KALAN YEMEK HAKKINIZ
        </div>
        <div style={{ paddingTop: "5px" }}>
          <div
            style={{
              padding: 0,
              minHeight: "48px",
              color: "#7f90a4",
              fontSize: "25px",
              textAlign: "center  "
            }}
          >
            {this.props.credit}
          </div>
          <Button
            onClick={() => {
              this.props.history.push("/payment");
            }}
            style={{
              color: "#fff",
              fontWeight: "bold",
              backgroundColor: "#F3565D",
              borderColor: "#f13e46",
              width: "100%"
            }}
          >
            SATIN AL
          </Button>
        </div>
      </Card>
    );
  }
}
