import React, { Component } from "react";
import { Table, Row, Col, Select, Button, DatePicker, Input, Icon } from "antd";
import Axios from "axios";
import { API_ADDRESS } from "../config";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";
import moment from "moment";
import Highlighter from "react-highlight-words";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default class DepartmantAndCanteenReports extends Component {
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            width: 188,
            marginBottom: 8,
            display: "block"
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Ara
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Sıfırla
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  state = {
    start_date: "",
    end_date:"",
    filterData: [],
    columns: [
      {
        title: "Adet",
        dataIndex: "count",
        align: "left",
        width: "10%"
      },

      {
        title: "Yemekhane",
        dataIndex: "canteen_definition",
        align: "left",
        width: "35%"
      },
      {
        title: "Departman",
        dataIndex: "department_definition",
        align: "left",
        width: "35%"
      },
      {
        title: "İşlem Tarihi",
        dataIndex: "reserved_date",
        align: "left",
        width: "20%",
        ...this.getColumnSearchProps("reserved_date"),
        sorter: (a, b) =>
          moment(a.reserved_date).unix() - moment(b.reserved_date).unix(),
        render: (text, record) => {
          moment.locale("tr");
          return moment(record.reserved_date).format("DD.MM.YYYY");
        }
      }
    ]
  };

  filter = () => {
    const { start_date, end_date } = this.state;
    Axios.post(
      API_ADDRESS + "/report/daily/department",
      {
        start_date,
        end_date
      },
      {
        headers: {
          Authorization: "Bearer " + SessionStorageDecrypt("user").token
        }
      }
    ).then(res => {
      this.setState({
        filterData: res.data.data
      });
    });
  };

  onChange = (value, dateString) => {
    this.setState({
      start_date: dateString[0],
      end_date: dateString[1]
    });
  };

  render() {
    return (
      <div>
        <Row gutter={[12, 12]}>
          <Col lg={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <b>Tarih</b>
              <RangePicker
                format="YYYY-MM-DD"
                placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
                onChange={this.onChange}
              />
            </div>
          </Col>

          <Col lg={4}>
            <div
              style={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <b>
                <br></br>
              </b>
              <Button onClick={this.filter}> Uygula </Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Table
            locale={{
              emptyText: <span>Veri yok</span>
            }}
            columns={this.state.columns}
            dataSource={this.state.filterData}
            rowKey={record => record.reserved_date + record.count}
          />
        </Row>
      </div>
    );
  }
}
