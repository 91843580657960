import React, { Component } from "react";
import Text from "antd/lib/typography/Text";
import { Empty, Row, Col, Select, Table, Button, Spin } from "antd";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";
import Axios from "axios";
import { API_ADDRESS } from "../config";
import message from "../helper";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import authActions from "../actions/authAction";
import { AxiosGet } from "../helper/Axios";

const { Option } = Select;

const { updateProps } = authActions;

class CancelFoodScreen extends Component {
  state = {
    loading: true,
    selectedUser: {},
    selectedRowKeys: [],
    dataSource: [],
    columns: [
      {
        title: "Tarih",
        dataIndex: "reserved_date",
        width: "30%",
        render: (text, record) => {
          return moment(record.reserved_date).format("DD.MM.YYYY");
        }
      },
      {
        title: "Menu",
        dataIndex: "menu",
        width: "70%"
      }
    ]
  };
  user = SessionStorageDecrypt("user");
  selectableUser = [];

  componentDidMount() {
    this.setState({
      dataSource: this.user.user_not_used_reserved_food_days
    });
  }

  cancelCredit = () => {
    Axios.post(
      API_ADDRESS + "/Food/ReloadCredit",
      {
        user_row_guid: this.user.user_row_guid,
        reserved_dates: this.state.selectedRowKeys
      },
      {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
    ).then(res => {
      if (res.data.code === 200) {
        message.success(1000, "İade işlemi başarılı");
        this.props.updateProps(res.data.data);
        this.props.history.push("/main");
      } else if (res.data.code === -2) {
        message.warning(1000, "Yemek hakkı daha önce iptal edilmiş.");
        AxiosGet("/account/" + this.user.user_row_guid).then(res => {
          this.props.updateProps(res);
        });
        this.props.history.push("/main");
      } else {
        message.warning(1000, "Hata oluştu");
      }
    });
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };
  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        {
          key: "all-data",
          text: "Tüm veriyi seç.",
          onSelect: () => {
            this.setState({
              selectedRowKeys: [...this.state.foodData.keys()] // 0...45
            });
          }
        }
      ]
    };
    return (
      <div>

        <b style={{
          fontFamily: "Avenir",
          textAlign: "left",
          color: "rgb(227, 10, 23)",
          alignContent: "center",
          fontSize: 20
        }}> Bir sonraki gün için iptal işlemi en geç 16:45' da yapılabilmektedir.</b>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={14} lg={24} xl={24}>
            <Table
              locale={{
                emptyText: <span>Veri yok</span>
              }}
              style={styles.maximizeScreen}
              rowSelection={rowSelection}
              columns={this.state.columns}
              rowKey={rowKey => rowKey.reserved_date}
              dataSource={this.state.dataSource}
            />
            <Button
              disabled={this.state.selectedRowKeys.length === 0}
              onClick={this.cancelCredit}
            >
              Kredi İade Et
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const styles = {
  cardHolder: {
    width: "100%",
    height: "100%",
    backgroundColor: "white"
  },
  textStyle: {
    fontWeight: "800",
    color: "#578ebe",
    fontSize: "15px",
    marginBottom: "10px"
  },
  searchBox: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    height: "100%"
  },
  maximizeScreen: {
    background: "white",
    width: "100%",
    height: "100%"
  }
};

export default connect(null, { updateProps })(CancelFoodScreen);
