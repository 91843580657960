import React, { Component } from "react";
import Text from "antd/lib/typography/Text";
import { List, Typography, Row, Col } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";

export default class Notices extends Component {
  render() {
    return (
      <div
        style={{
          paddingTop: "12px",
          paddingRight: "20px",
          paddingBottom: "15px",
          paddingLeft: "20px",
          backgroundColor: "#fff",
          width: "100%",
          minHeight: "395px",
          height: "100%"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px"
          }}
        >
          <Text
            style={{
              fontWeight: 800,
              color: "#578ebe",
              fontSize: "15px"
            }}
          >
            DUYURULAR VE MESAJLAR
          </Text>
          <Text
            style={{
              fontWeight: 800,
              color: "#578ebe",
              fontSize: "15px"
            }}
          >
            <Link style={{ color: "#578ebe" }} to="/notices">
              Tümünü Gör
            </Link>
          </Text>
        </div>
        <List
          locale={{
            emptyText: <span>Veri yok</span>
          }}
          //header={<div>Header</div>}
          //={<div>Footer</div>}
          bordered
          dataSource={this.props.annoncement}
          renderItem={item => (
            <List.Item>
              <Row type="flex">
                <Col span={24}>
                  <b>{moment(item.row_create_date, "YYYYMMDD").fromNow()}</b>
                </Col>
                <Col span={24}>{item.announcement}</Col>
              </Row>
            </List.Item>
          )}
        />
      </div>
    );
  }
}
