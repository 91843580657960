import React, { Component } from "react";
import { Table, Row, Col, Select, Button, DatePicker } from "antd";
import Axios from "axios";
import { API_ADDRESS } from "../config";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default class UsedReportsScreen extends Component {
  state = {
    departments: [],
    jobs: [],
    offices: [],
    users: [],
    selected_department: "",
    selected_office: "",
    selected_user: "",
    selected_job: "",
    start_date: "",
    end_date: "",
    filterData: [],
    columns: [
      {
        title: "Tarih",
        dataIndex: "operation_date",
        width: "20%",
        align: "left",
        render: (text, record) => {
          return moment(record.operation_date).format("DD.MM.YYYY");
        }
      },
      {
        title: "Ad Soyad",
        dataIndex: "full_name",
        width: "20%",
        align: "left"
      },
      {
        title: "Güncel Bakiye",
        dataIndex: "credit_after_operation",
        width: "20%",
        align: "left"
      },
      {
        title: "İşlem Tipi",
        dataIndex: "operation_type_definition",
        width: "20%",
        align: "left"
      }
    ]
  };
  componentDidMount() {
    Axios.get(API_ADDRESS + "/Report/Filters", {
      headers: {
        Authorization: "Bearer " + SessionStorageDecrypt("user").token
      }
    }).then(res => {
      const { departments, jobs, offices, users } = res.data.data;
      this.setState({
        departments,
        jobs,
        offices,
        users
      });
    });
  }

  renderDepartments = () => {
    return (
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Departman Seç"
        optionFilterProp="children"
        allowClear
        onChange={value => {
          this.setState({
            selected_department: value
          });
        }}
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
      >
        {this.state.departments.map(item => (
          <Option key={item.department_row_guid}>
            {" "}
            {item.department_definition}{" "}
          </Option>
        ))}
      </Select>
    );
  };

  renderOffices = () => {
    return (
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Ofis seç"
        optionFilterProp="children"
        allowClear
        onChange={value => {
          this.setState({
            selected_office: value
          });
        }}
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
      >
        {this.state.offices.map(item => (
          <Option key={item.row_guid}> {item.definition} </Option>
        ))}
      </Select>
    );
  };

  renderUsers = () => {
    return (
      <Select
        showSearch
        optionFilterProp="children"
        style={{ width: 200 }}
        placeholder="Kullanıcı Seç"
        allowClear
        onChange={value => {
          this.setState({
            selected_user: value
          });
        }}
        filterOption={(input, option) => {
          return (
            option.props.children
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          );
        }}
      >
        {this.state.users.map(item => (
          <Option key={item.user_row_guid}> {item.full_name} </Option>
        ))}
      </Select>
    );
  };

  renderJobs = () => {
    return (
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Görev Seç"
        optionFilterProp="children"
        allowClear
        onChange={value => {
          this.setState({
            selected_job: value
          });
        }}
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
      >
        {this.state.jobs.map(item => (
          <Option key={item.job_row_guid}> {item.definition} </Option>
        ))}
      </Select>
    );
  };

  filter = () => {
    const {
      selected_department,
      selected_office,
      selected_user,
      selected_job,
      start_date,
      end_date
    } = this.state;
    Axios.post(
      API_ADDRESS + "/Report/Report",
      {
        selected_department,
        selected_office,
        selected_user,
        selected_job,
        start_date,
        end_date
      },
      {
        headers: {
          Authorization: "Bearer " + SessionStorageDecrypt("user").token
        }
      }
    ).then(res => {
      this.setState({
        filterData: res.data.data
      });
    });
  };

  onChange = (value, dateString) => {
    this.setState({
      start_date: dateString[0],
      end_date: dateString[1]
    });
  };

  render() {
    return (
      <div>
        <Row gutter={[12, 12]}>
          {this.props.allFilter ? (
            <div>
              <Col lg={4}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <b>Departman</b>

                  {this.renderDepartments()}
                </div>
              </Col>
              <Col lg={4}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <b>Ofis</b>

                  {this.renderOffices()}
                </div>
              </Col>
              <Col lg={4}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <b>İş</b>

                  {this.renderJobs()}
                </div>
              </Col>
              <Col lg={4}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <b>Kişi</b>

                  {this.renderUsers()}
                </div>
              </Col>
            </div>
          ) : null}
          <Col lg={8}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <b>Tarih</b>
              <RangePicker
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
                onChange={this.onChange}
              />
            </div>
          </Col>
          <Col lg={4}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <b>
                <br></br>
              </b>
              <Button onClick={this.filter}> Uygula </Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Table
            locale={{
              emptyText: <span>Veri yok</span>
            }}
            columns={this.state.columns}
            dataSource={this.state.filterData}
            rowKey={record => record.operation_date}
          />
        </Row>
      </div>
    );
  }
}
