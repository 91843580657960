import React, { Component } from "react";
import UserCard from "../Component/UserCard";
import { Layout, Row, Col } from "antd";
import RemainingCredit from "../Component/RemainingCredit";
import SpendingTable from "../Component/SpendingTable";
import Notices from "../Component/Notices";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";
import { AxiosGet } from "../helper/Axios";
import Meals from "../Component/Meals";
export default class MainScreen extends Component {
  state = {
    user: {
      user_annoncements: []
    },
    whatisonmeal: []
  };

  componentDidMount = () => {
    let user = SessionStorageDecrypt("user");
    if (user) {
      this.setState({ user: user });
    }

    AxiosGet("/food/menu").then(res => {
      this.setState({
        annoncements: this.state.user.user_annoncements
          ? this.state.user.user_annoncements.slice(0, 5)
          : [],
        whatisonmeal: res.slice(0, 5)
      });
    });
  };

  render() {
    return (
      <Layout>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} lm={12} lg={12}>
            <Notices annoncement={this.state.annoncements} />
          </Col>
          <Col xs={24} sm={24} lm={12} lg={12}>
            <Meals list={this.state.whatisonmeal} />
          </Col>
          <Col xs={24} sm={24} lg={24}>
            <SpendingTable
              pageSize={5}
              creditHistory={this.state.user.user_credit_history}
            />
          </Col>
        </Row>
      </Layout>
    );
  }
}
