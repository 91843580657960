import React, { Component } from "react";
import ProfileCard from "../Component/ProfileCard";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";

export default class ProfileScreen extends Component {
  state = {
    user: []
  };
  componentDidMount() {
    this.setState({
      user: SessionStorageDecrypt("user")
    });
  }
  render() {
    return (
      <div>
        <ProfileCard
          history={this.props.history}
          user={this.state.user}
          token={this.state.user.token}
          is_admin={false}
          password={true}
        ></ProfileCard>
      </div>
    );
  }
}
