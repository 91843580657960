import React, { Component } from "react";
import { Table, Row, Col, Select, Button, DatePicker, Input } from "antd";
import Axios from "axios";
import { API_ADDRESS } from "../config";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";
import moment from "moment";
import message from "../helper";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default class DepartmantAndPersonelReports extends Component {

    token = SessionStorageDecrypt("user").token;
    state = {
        canteens: [],
        department: [],
        selected_canteen: "",
        start_date: "",

        filterData: [],
        columns: [

            {
                title: "Rezervasyon Tarihi",
                dataIndex: "reserved_date",
                width: "25%",
                align: "left",
                render: (text, render) => {
                    return moment(render.reserved_date).format("DD.MM.YYYY")
                }
            },
            {
                title: "Personel",
                dataIndex: "full_name",

                align: "left"
            },
            {
                title: "Yemekhane",
                dataIndex: "canteen_definition",
                align: "left"
            },
            // {
            //     title: "Departman",
            //     dataIndex: "department_definition",

            //     align: "left"
            // },

        ]
    };
    onChange = (value, dateString) => {

        this.setState({
            start_date: dateString
        });
    };

    filter = () => {
     
        const {
            selected_canteen,
            start_date,
            keyword,

        } = this.state;

        Axios.post(
            API_ADDRESS + "/report/daily/DepartmentAndPersonel",
            {
                selected_canteen,
                start_date,
                keyword

            },
            {
                headers: {
                    Authorization: "Bearer " + SessionStorageDecrypt("user").token
                }
            }
        ).then(res => {
            console.log("DATA", res)
            var i = 0;
            this.setState({
                filterData: res.data.data.map(iter => {
                    const newData = {
                        ...iter,
                        id: i
                    }
                    i++
                    return newData
                })
            });
        });
    };
    getAllCanteen() {
        Axios.post(
            API_ADDRESS + "/report/daily/DepartmentAndPersonel",
            {
                start_date: Date.now,

            },
            {
                headers: {
                    Authorization: "Bearer " + this.token
                }
            }).then(res => {

                this.setState({
                    department: res.data.data.filter(data => (data.is_active = true))
                });
            });
    }
    getAllCanteens() {
        Axios.get(API_ADDRESS + "/Canteen", {
            headers: {
                Authorization: "Bearer " + this.token
            }
        }).then(res => {
            this.setState({
                canteens: res.data.data.filter(data => (data.is_active = true))
            });
        });
    }

    componentDidMount() {
        this.getAllCanteens();
        this.filter();
    }

    render() {
        return (
            <div>
                <Row gutter={[12, 12]}>
                    <Col lg={3}>

                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <b>
                                <br></br>
                            </b>
                            <Input
                                placeholder="Ara ..."
                                value={this.state.keyword}
                                onChange={e => this.setState({ keyword: e.target.value })}
                                style={{ width: "90%", marginRight: "10px" }}
                            />
                        </div>
                    </Col>
                    <Col lg={3}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <b>
                                <br></br>
                            </b>
                            <Select
                                showSearch
                                style={{ width: 200 }}
                                placeholder="Yemekhane Seç"
                                optionFilterProp="children"
                                allowClear
                                onChange={value => {
                                    this.setState({
                                        selected_canteen: value
                                    });
                                }}
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toString()
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.canteens.map(item => (
                                    <Option key={item.row_guid}>
                                        {" "}
                                        {item.definition}{" "}
                                    </Option>
                                ))}
                            </Select>
                        </div>

                    </Col>
                    <Col lg={3}>

                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <b>
                                <br></br>
                            </b>
                            <DatePicker
                                showTime={{ format: "HH:mm" }}
                                format="DD-MM-YYYY"
                                placeholder={"Tarih"}
                                onChange={this.onChange}

                            />
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <b>
                                <br></br>
                            </b>
                            <Button onClick={this.filter}> Uygula </Button>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Table
                        locale={{
                            emptyText: <span>Veri yok</span>
                        }}
                        columns={this.state.columns}
                        dataSource={this.state.filterData}
                        rowKey={record => record.id}
                    />
                </Row>
            </div>
        )
    }
}
