/*class SessionStorageSecurty {
    SHA512 = require("crypto-js/sha512");
    SecretKey= 'MeSNeT.2019';
    /*constructor(yukseklik, genislik) {
      this.yukseklik = yukseklik;
      this.genislik = genislik;
    }
    
}*/
const Cryptr = require("cryptr");
const cryptr = new Cryptr("!MeSNeT.2019.Secret=");
export const SessionStorageEncrypt = (key, data) => {
  var stringData = JSON.stringify(data);
  sessionStorage.setItem(key, cryptr.encrypt(stringData));

  return sessionStorage.setItem(key, cryptr.encrypt(stringData));
};

export const SessionStorageDecrypt = key => {
  return sessionStorage.getItem(key)
    ? JSON.parse(cryptr.decrypt(sessionStorage.getItem(key)))
    : null;
};
