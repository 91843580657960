import React, { Component } from "react";
import { Button, Spin, Icon, Input, Col } from "antd";
import Axios from "axios";
import { API_ADDRESS } from "../config";
import message from "../helper";

export default class ForgotPasswordScreen extends Component {
  state = {
    mail: "",
    loading: false
  };

  forgotPassword = () => {
    this.setState({
      loading: true
    });
    Axios.post(
      API_ADDRESS + "/Account/ResetPassword/" + this.state.mail,
      {}
    ).then(res => {
      if (res.data.code === 200) {
        message.success(1000, "Şifre değiştirme başarılı.");
        this.props.history("/signin");
      } else {
        message.warning(1500, res.data.message);
      }
      this.setState({
        loading: false
      });
    });
  };

  render() {
    return (
      <div style={styles.containerStyle}>
        <Spin
          indicator={
            <Icon
              type="loading"
              style={{ fontSize: 30, color: "#bd4d50" }}
              spin
            />
          }
          spinning={this.state.loading}
        >
          <div style={styles.loginContainerStyle}>
            <a href="/signin">
              <img
                alt="img"
                src={require("../images/login_logo.jpg")}
                style={styles.logoStyle}
              ></img>
            </a>
            <p>
              Lütfen şifresini unuttuğunuz hesabın e-posta adresini giriniz.
            </p>
            <Input
              size="large"
              value={this.state.mail}
              onChange={e => {
                this.setState({ mail: e.target.value });
              }}
              placeholder="E-posta Adresi"
            />
            <Col>
              <Button
                onClick={() => {
                  this.props.history.push("/signin");
                }}
                style={{ float: "left", marginTop: "5px" }}
              >
                Geri
              </Button>
              <Button
                onClick={this.forgotPassword}
                style={{ float: "right", marginTop: "5px" }}
              >
                Gönder
              </Button>
            </Col>
          </div>
        </Spin>
      </div>
    );
  }
}

const styles = {
  containerStyle: {
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "flex",
    flex: 1,
    height: "100vh",
    overFlowY: "hidden",
    alignItems: "center",
    justifyContent: "center",
    background:
      "radial-gradient(circle farthest-corner at center,rgba(54, 57, 64, 0) 0,rgba(54, 57, 64, .8) 100%),#FFF"
  },
  loginContainerStyle: {
    backgroundColor: "white",
    padding: "20px",
    maxWidth: "400px",
    height: "40vh",
    borderRadius: "2%",
    display: "block"
  },
  logoStyle: {
    position: "abdolute",
    width: "40%",
    marginBottom: "20px",
    marginLeft: "50%",
    transform: "translate(-50%)"
  }
};
