import React, { Component } from "react";
import "antd/dist/antd.css";
import "./App.css";
import Boot from "./boot";
import { Provider } from "react-redux";
import Routes from "./routes";
import { store, history } from "./store";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Routes history={history} />
      </Provider>
    );
  }
}
Boot()
  .then(() => App())
  .catch(error => console.error(error))

export default (App);
