import React, { Component } from "react";
import Text from "antd/lib/typography/Text";
import { Table, Button, Icon, Input } from "antd";
import Highlighter from "react-highlight-words";

export default class TempTable extends Component {
  render() {
    return (
      <div
        style={{
          paddingTop: "12px",
          paddingRight: "20px",
          paddingBottom: "15px",
          paddingLeft: "20px",
          backgroundColor: "#fff",
          width: "100%"
        }}
      >
        <Text
          style={{
            fontWeight: 800,
            color: "#578ebe",
            fontSize: "15px",
            marginBottom: "30px"
          }}
        >
          {this.props.tableTitle}
        </Text>
        <Table
          className="components-table-demo-nested"
          style={(this.props.style, { marginTop: "10px" })}
          size="small"
          columns={this.props.columns ? this.props.columns : null}
          dataSource={this.props.data ? this.props.data : []}
          footer={this.props.footer ? this.props.footer : false}
          rowKey={this.props.rowKey}
          tableLayout="fixed"
          loading={this.props.loading}
          onChange={this.props.onChange}
          pagination={{
            pageSize: this.props.pageSize ? this.props.pageSize : 10,
            position: "bottom",
            bottom: 0,
            float: "left"
          }}
          locale={{
            emptyText: <span>Veri yok</span>
          }}
        />
      </div>
    );
  }
}
