import React, { Component } from "react";
import Axios from "axios";
import { API_ADDRESS } from "../config";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";
import { Table, Input, Button, Select, Row, Col, Spin } from "antd";
import message from "../helper";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;
export default class SendSMSScreen extends Component {
  user = SessionStorageDecrypt("user");
  state = {
    loading: true,
    blood_type: ["A+", "A-", "AB+", "AB-", "B+", "B-", "0+", "0-"],
    filter: {
      selected_blood_type: "",
      selected_deparment: "",
      selected_job: "",
      selected_office: ""
    },
    selected_blood_type: "",
    selectedRowKeys: [],
    departments: [],
    offices: [],
    jobs: [],
    allData: [],
    users: [],
    columns: [
      {
        title: "Ad Soyad",
        dataIndex: "full_name",
        width: "16,6%"
      },
      {
        title: "Departman Adı",
        dataIndex: "department_definition",
        width: "16,6%"
      },
      {
        title: "Görev Adı",
        dataIndex: "job_definition",
        width: "16,6%"
      },
      {
        title: "Ofis Adı",
        dataIndex: "office_definition",
        width: "16,6%"
      },
      {
        title: "Kan Grubu",
        dataIndex: "blood_type",
        width: "16,6%"
      },
      {
        title: "Telefon Numarası",
        dataIndex: "mobile_phone",
        width: "16,6%"
      }
    ],
    smsTitle: ""
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  componentDidMount() {
    Axios.get(API_ADDRESS + "/Report/Filters", {
      headers: {
        Authorization: "Bearer " + SessionStorageDecrypt("user").token
      }
    }).then(res => {
      const { departments, jobs, offices, users } = res.data.data;
      this.setState({
        departments,
        jobs,
        offices,
        users,
        allData: users,
        loading: false
      });
      this.tempUser = this.state.allData;
    });
  }

  tempUser = [];
  renderDepartments = () => {
    return (
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Departman Seç"
        optionFilterProp="children"
        allowClear
        onChange={value => {
          this.setState({
            filter: { ...this.state.filter, selected_deparment: value }
          });
        }}
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
      >
        {this.state.departments.map(item => (
          <Option key={item.department_row_guid}>
            {" "}
            {item.department_definition}{" "}
          </Option>
        ))}
      </Select>
    );
  };
  renderBloodTypes = () => {
    return (
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Kan Grubu Seç"
        optionFilterProp="children"
        allowClear
        onChange={value => {
          this.setState({
            filter: { ...this.state.filter, selected_blood_type: value }
          });
        }}
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
      >
        {this.state.blood_type.map(item => (
          <Option key={item}> {item} </Option>
        ))}
      </Select>
    );
  };
  renderJobs = () => {
    return (
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Görev Seç"
        optionFilterProp="children"
        allowClear
        onChange={value => {
          this.setState({
            filter: { ...this.state.filter, selected_job: value }
          });
        }}
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
      >
        {this.state.jobs.map(item => (
          <Option key={item.job_row_guid}> {item.definition} </Option>
        ))}
      </Select>
    );
  };

  renderOffices = () => {
    return (
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Ofis seç"
        optionFilterProp="children"
        allowClear
        onChange={value => {
          this.setState({
            filter: { ...this.state.filter, selected_office: value }
          });
        }}
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
      >
        {this.state.offices.map(item => (
          <Option key={item.row_guid}> {item.definition} </Option>
        ))}
      </Select>
    );
  };
  sendSMS = () => {
    this.setState({ loading: true });
    const { smsTitle, selectedRowKeys } = this.state;
    smsTitle && selectedRowKeys
      ? Axios.post(
          API_ADDRESS + "/Information/SendSms",
          {
            message_to_send: smsTitle,
            users_to_send_sms: selectedRowKeys
          },
          {
            headers: {
              Authorization: "Bearer " + this.user.token
            }
          }
        )
          .then(res => {
            this.setState({
              loading: false,
              smsTitle: "",
              selectedRowKeys: []
            });

            if (res.data.code === 200) {
              message.success(1500, "Sms gönderimi başarılı");
            } else {
              message.warning(1500, res.data.message);
            }
          })
          .catch(err => {
            message.error();
          })
      : message.warning("Doldurulması gereken alanlar mevcut.");
  };

  render() {
    const filterData = this.state.users
      .filter(user => {
        return this.state.filter.selected_blood_type
          ? this.state.filter.selected_blood_type === user.blood_type
          : true;
      })
      .filter(user => {
        return this.state.filter.selected_office
          ? this.state.filter.selected_office === user.office_row_guid
          : true;
      })
      .filter(user => {
        return this.state.filter.selected_job
          ? this.state.filter.selected_job === user.job_title_row_guid
          : true;
      })
      .filter(user => {
        return this.state.filter.selected_deparment
          ? this.state.filter.selected_deparment === user.department_row_guid
          : true;
      });
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };
    return (
      <div>
        {this.state.loading ? (
          <Spin />
        ) : (
          <div>
            <Row gutter={[8, 8]} style={{ marginBottom: "20px" }}>
              <Col xs={12} md={12} lg={6}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <b>Departman</b>

                  {this.renderDepartments()}
                </div>
              </Col>
              <Col xs={12} md={12} lg={6}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <b>Ofis</b>

                  {this.renderOffices()}
                </div>
              </Col>
              <Col xs={12} md={12} lg={6}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <b>Görev</b>

                  {this.renderJobs()}
                </div>
              </Col>
              <Col xs={12} md={12} lg={6}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <b>Kan Grubu</b>

                  {this.renderBloodTypes()}
                </div>
              </Col>
            </Row>
            <Table
              locale={{
                emptyText: <span>Veri yok</span>
              }}
              rowSelection={rowSelection}
              columns={this.state.columns}
              dataSource={filterData}
              rowKey={record => record.user_row_guid}
            />
            <TextArea
              placeholder="Lütfen göndermek istediğiniz metni giriniz."
              value={this.state.smsTitle}
              style={{ marginBottom: "20px" }}
              onChange={e => {
                this.setState({
                  smsTitle: e.target.value
                });
              }}
            />
            <Button loading={this.state.loading} onClick={this.sendSMS}>
              SMS Gönder
            </Button>
          </div>
        )}
      </div>
    );
  }
}
