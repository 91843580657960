import React, { Component } from "react";
import TempTable from "../Component/common/TempTable";
import message from "../helper";
import Axios from "axios";
import { API_ADDRESS } from "../config";
import { Button, Input, Select, Icon } from "antd";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";
import Highlighter from "react-highlight-words";

const { Option } = Select;
export default class Job extends Component {
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            width: 188,
            marginBottom: 8,
            display: "block"
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Ara
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Sıfırla
        </Button>
      </div>
    ),

    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  token = SessionStorageDecrypt("user").token;
  state = {
    searchText: "",
    searchedColumn: "",

    data: null,
    foodsPrice: [],
    selectedFood: "",
    job_definition: "",
    selectedToUpdate: {
      editFood: ""
    },
    columns: [
      {
        title: "Görev",
        dataIndex: "definition",
        width: "45%",
        align: "left",
        ...this.getColumnSearchProps("definition")
      },
      {
        title: "Yemek Tipi",
        dataIndex: "food_price_definition",
        width: "35%",
        align: "left",
        render: (text, record) => {
          return (
            <Select
              style={{ width: "100%" }}
              placeholder="Yemek Tipi"
              defaultValue={record.food_price_row_guid}
              value={
                this.state.data.find(v => v.job_row_guid == record.job_row_guid)
                  .food_price_row_guid
              }
              onChange={value =>
                this.onChangeFoodPriceEdit(value, record.job_row_guid)
              }
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.foodsPrice ? (
                this.state.foodsPrice.map(item => (
                  <Option key={item.row_guid}>{item.definition}</Option>
                ))
              ) : (
                <Option key={record.food_price_row_guid}>
                  {record.food_price_definition}
                </Option>
              )}
            </Select>
          );
        }
      },
      {
        title: "İşlemler",
        key: "action",
        align: "left",
        width: "20%",
        render: (text, record) => {
          return (
            <div style={{ display: "flex", flex: 1 }}>
              <Button
                type="primary"
                onClick={() => {
                  this.deleteJob(record.job_row_guid);
                }}
                style={{ width: "50%" }}
              >
                Sil
              </Button>
              <Button
                onClick={() => {
                  this.updateJob(record.definition, record.job_row_guid);
                }}
                style={{ width: "50%", color: "black" }}
              >
                Güncelle
              </Button>
            </div>
          );
        }
      }
    ]
  };

  getFoodPrice = () => {
    Axios.get(API_ADDRESS + "/Food/Price", {
      headers: {
        Authorization: "Bearer " + this.token
      }
    }).then(res => {
      this.setState({
        foodsPrice: res.data.data.filter(data => (data.is_active = true))
      });
    });
  };

  componentDidMount() {
    Axios.get(API_ADDRESS + "/Job", {
      headers: {
        Authorization: "Bearer " + this.token
      }
    }).then(res => {
      this.setState({
        data: res.data.data
      });
    });
    this.getFoodPrice();
  }

  onChangeFood = value => {
    this.setState({
      selectedFood: value
    });
  };

  onChangeFoodPriceEdit = (value, row_guid) => {
    let data = this.state.data;
    data.find(v => v.job_row_guid == row_guid).food_price_row_guid = value;

    this.setState({ data: data });
  };

  renderFooter = () => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          alignContent: "center"
        }}
      >
        <Input
          placeholder="Görev adı giriniz ..."
          value={this.state.job_definition}
          onChange={e => this.setState({ job_definition: e.target.value })}
          style={{ width: "45%", marginRight: "8px" }}
        />
        <Select
          style={{ width: "34%", marginRight: 8 }}
          placeholder="Yemek Tipi seç"
          onChange={this.onChangeFood}
          value={this.state.selectedFood}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {this.state.foodsPrice
            ? this.state.foodsPrice.map(item => (
                <Option key={item.row_guid}>{item.definition}</Option>
              ))
            : null}
        </Select>

        <div
          style={{
            width: "20%",
            marginLeft: "8px",
            display: "flex",
            flex: 1,
            justifyContent: "flex-end"
          }}
        >
          <Button
            type="primary"
            onClick={this.insertJob}
            style={{ width: "100%", align: "right" }}
          >
            Ekle
          </Button>
        </div>
      </div>
    );
  };

  insertJob = () => {
    const { job_definition, selectedFood } = this.state;
    job_definition && selectedFood
      ? Axios.post(
          API_ADDRESS + "/Job",
          {
            definition: job_definition,
            food_price_row_guid: selectedFood
          },
          {
            headers: {
              Authorization: "Bearer " + this.token
            }
          }
        )
          .then(res => {
            const { data, code } = res.data;
            data["job_row_guid"] = data.row_guid;
            if (code === 200) {
              this.setState({
                data: [...this.state.data, data],
                selectedFood: "",
                job_definition: ""
              });
              message.success(1000, "Ekleme işlemi başarılı");
            } else {
              message.warning(1000, "Kayıt işlemi başarısız");
            }
          })
          .catch(err => {
            message.error(1000, "Hata oluştu.");
          })
      : message.warning(1300, "Doldurmanız gereken alanlar mevcut.");
  };
  deleteJob = id => {
    Axios.put(
      API_ADDRESS + "/Job",
      {
        row_guid: id,
        is_deleted: true
      },
      {
        headers: {
          Authorization: "Bearer " + this.token
        }
      }
    )
      .then(res => {
        if (res.data.code === 200) {
          const data = [...this.state.data];
          message.success(1000, "işlem başarılı");
          this.setState({
            data: data.filter(item => item.job_row_guid !== id)
          });
        } else {
          message.warning(1000, res.data.message);
        }
      })
      .catch(err => {
        message.error(1000, err);
      });
  };
  updateJob = (definition, id) => {
    let row_guid = this.state.data.find(v => v.job_row_guid == id)
      .food_price_row_guid;

    Axios.put(
      API_ADDRESS + "/Job",
      {
        row_guid: id,
        food_price_row_guid: row_guid
      },
      {
        headers: {
          Authorization: "Bearer " + this.token
        }
      }
    )
      .then(res => {
        if (res.data.code === 200) {
          message.success(
            1000,
            definition + " adlı kayıt başarılı bir şekilde güncellendi."
          );
        } else {
          message.warning(1000, res.data.message);
        }
      })
      .catch(err => {
        message.error();
      });
  };

  render() {
    const { data, selectedToUpdate } = this.state;
    return (
      <div style={{ display: "flex", flex: 1, margin: 10 }}>
        <TempTable
          pageSize={50}
          columns={this.state.columns}
          data={this.state.data}
          footer={this.renderFooter}
          rowKey={record => record.job_row_guid}
          tableTitle={"Görevler"}
          loading={data ? false : true}
        />
      </div>
    );
  }
}
