import React, { Component } from "react";
import { Table, Row, Col, Select, Button, DatePicker } from "antd";
import Axios from "axios";
import { API_ADDRESS } from "../config";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";
import moment from "moment";
import message from "../helper";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default class DepartmantReport extends Component {

    token = SessionStorageDecrypt("user").token;
    state = {
        departments: [],
        selected_department: "",
        start_date: "",
        end_date: "",
        filterData: [],
        columns: [
            {
                title: "Departman",
                dataIndex: "department_definition",
                width: "50%",
                align: "left"
            },
            {
                title: "Adet",
                dataIndex: "count",
                width: "20%",
                align: "left"
            },
            {
                title: "Tarih",
                dataIndex: "operation_date",
                width: "30%",
                align: "left",
                render: (text, render) => {
                    return moment(render.operation_date).format("DD.MM.YYYY")
                }
            },
        ]
    };

    getAllDepartment() {
        Axios.get(API_ADDRESS + "/Department", {
            headers: {
                Authorization: "Bearer " + this.token
            }
        })
            .then(res => {
                if (res.data.code === 200) {
                    return res.data.data.filter(data => {
                        return (data.is_active = true);
                    });
                } else {
                    this.setState({ loading: false });
                    message.warning("Departman Bulunamadı");
                }
            })
            .then(cont => {
                //contune
                this.setState({
                    departments: cont
                });
            });

    }

    renderDepartments = () => {
        return (
            <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Departman Seç"
                optionFilterProp="children"
                allowClear
                onChange={value => {
                    this.setState({
                        selected_department: value
                    });
                }}
                filterOption={(input, option) =>
                    option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }
            >
                {this.state.departments.map(item => (
                    <Option key={item.department_row_guid}>
                        {" "}
                        {item.department_definition}{" "}
                    </Option>
                ))}
            </Select>
        );
    };
    onChange = (value, dateString) => {
        this.setState({
            start_date: dateString[0],
            end_date: dateString[1]
        });
    };
    filter = () => {
        const {
            selected_department,
            start_date,
            end_date
        } = this.state;
        Axios.post(
            API_ADDRESS + "/Report/Department",
            {
                selected_department,
                start_date,
                end_date
            },
            {
                headers: {
                    Authorization: "Bearer " + SessionStorageDecrypt("user").token
                }
            }
        ).then(res => {
            this.setState({
                filterData: res.data.data
            });
        });
    };

    componentDidMount() {
        this.getAllDepartment();
        this.filter();
    }
    render() {
        return (
            <div>
                <Row gutter={[12, 12]}>
                    <Col lg={5}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <b>Departman</b>

                            {this.renderDepartments()}
                        </div>
                    </Col>
                    <Col lg={9}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <b>Tarih</b>
                            <RangePicker
                                showTime={{ format: "HH:mm" }}
                                format="YYYY-MM-DD HH:mm"
                                placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
                                onChange={this.onChange}
                            />
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <b>
                                <br></br>
                            </b>
                            <Button onClick={this.filter}> Uygula </Button>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Table
                        locale={{
                            emptyText: <span>Veri yok</span>
                        }}
                        columns={this.state.columns}
                        dataSource={this.state.filterData}
                        rowKey={record => record.operation_date + record.department_definition}
                    />
                </Row>
            </div>
        )
    }
}
