import React, { Component } from "react";
import TempTable from "../Component/common/TempTable";
import message from "../helper";
import Axios from "axios";
import { API_ADDRESS } from "../config";
import { Button, Input, Select } from "antd";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";

const { Option } = Select;

export default class Department extends Component {
  token = SessionStorageDecrypt("user").token;
  state = {
    data: [],
    department_definition: "",
    offices: [],
    canteens: [],
    selectedCanteen: "",
    selectedGroup: "",
    selectedOffice: "",
    selectedToUpdate: {
      editGroup: "",
      editCanteen: "",
      editOffice: "",
      row_guid: ""
    },
    groups: [],
    columns: [
      {
        title: "Departman",
        dataIndex: "department_definition",
        width: "20%",
        align: "left",
        render: (text, record) => {
          return record.department_definition
            ? record.department_definition
            : record.definition;
        }
      },
      {
        title: "Daire",
        dataIndex: "office_definition",
        width: "23%",
        align: "left",
        render: (text, record) => {
          return (
            <Select
              style={{ width: "100%" }}
              placeholder="Daire"
              defaultValue={record.office_row_guid}
              value={
                this.state.selectedToUpdate.row_guid ===
                  record.department_row_guid &&
                this.state.selectedToUpdate.editOffice
                  ? this.state.selectedToUpdate.editOffice
                  : record.office_row_guid
              }
              onChange={value =>
                this.onChangeOfficeEdit(value, record.department_row_guid)
              }
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.offices ? (
                this.state.offices.map(item => (
                  <Option key={item.row_guid}>{item.definition}</Option>
                ))
              ) : (
                <Option key={record.office_row_guid}>
                  {record.office_definition}
                </Option>
              )}
            </Select>
          );
        }
      },
      {
        title: "Personel Grubu",
        dataIndex: "group_definition",
        width: "23%",
        align: "left",
        render: (text, record) => {
          return (
            <Select
              style={{ width: "100%" }}
              placeholder="grup"
              defaultValue={record.group_row_guid}
              value={
                this.state.selectedToUpdate.row_guid ===
                  record.department_row_guid &&
                this.state.selectedToUpdate.editGroup
                  ? this.state.selectedToUpdate.editGroup
                  : record.group_row_guid
              }
              onChange={value =>
                this.onChangeGroupEdit(value, record.department_row_guid)
              }
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.groups ? (
                this.state.groups.map(item => (
                  <Option key={item.row_guid}>{item.definition}</Option>
                ))
              ) : (
                <Option key={record.group_row_guid}>
                  {record.group_definition}
                </Option>
              )}
            </Select>
          );
        }
      },
      {
        title: "Yemekhane",
        dataIndex: "canteen_definition",
        width: "23%",
        align: "left",
        render: (text, record) => {
          return (
            <Select
              style={{ width: "100%" }}
              placeholder="Canteen"
              defaultValue={record.canteen_row_guid}
              onChange={value =>
                this.onChangeCanteenEdit(value, record.department_row_guid)
              }
              value={
                this.state.selectedToUpdate.row_guid ===
                  record.department_row_guid &&
                this.state.selectedToUpdate.editCanteen
                  ? this.state.selectedToUpdate.editCanteen
                  : record.canteen_row_guid
              }
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.canteens ? (
                this.state.canteens.map(item => (
                  <Option key={item.row_guid}>{item.definition}</Option>
                ))
              ) : (
                <Option key={record.canteen_row_guid}>
                  {record.canteen_definition}
                </Option>
              )}
            </Select>
          );
        }
      },
      {
        title: "İşlemler",
        key: "action",
        align: "left",
        width: "11%",
        render: (text, record) => {
          return (
            <div style={{ display: "flex", flex: 1 }}>
              <Button
                type="primary"
                onClick={() => {
                  this.deleteDepartment(record.department_row_guid);
                }}
                style={{ width: "40%" }}
              >
                Sil
              </Button>
              <Button
                disabled={
                  this.state.selectedToUpdate.row_guid ===
                  record.department_row_guid
                    ? false
                    : true
                }
                onClick={() => {
                  this.updateDepartment(
                    record.department_definition,
                    record.department_row_guid
                  );
                }}
                style={{ width: "60%", color: "black" }}
              >
                Güncelle
              </Button>
            </div>
          );
        }
      }
    ]
  };

  getGroup = () => {
    Axios.get(API_ADDRESS + "/Group", {
      headers: {
        Authorization: "Bearer " + this.token
      }
    }).then(res => {
      if (res.data.code === 200) {
        this.setState({
          groups: res.data.data.filter(data => (data.is_active = true))
        });
      }
    });
  };
  getOffice = () => {
    Axios.get(API_ADDRESS + "/Office", {
      headers: {
        Authorization: "Bearer " + this.token
      }
    }).then(res => {
      this.setState({
        offices: res.data.data.filter(data => (data.is_active = true))
      });
    });
  };
  getCanteen = () => {
    Axios.get(API_ADDRESS + "/Canteen", {
      headers: {
        Authorization: "Bearer " + this.token
      }
    }).then(res => {
      this.setState({
        canteens: res.data.data.filter(data => (data.is_active = true))
      });
    });
  };

  componentDidMount() {
    Axios.get(API_ADDRESS + "/Department", {
      headers: {
        Authorization: "Bearer " + this.token
      }
    })
      .then(res => {
        if (res.data.code === 200) {
          return res.data.data.filter(data => {
            return (data.is_active = true);
          });
        } else {
          this.setState({ loading: false });
          message.warning("Departman Bulunamadı");
        }
      })
      .then(cont => {
        //contune

        this.setState({
          data: cont
        });
      });
    this.getOffice();
    this.getGroup();
    this.getCanteen();
  }

  onChangeGroup = value => {
    this.setState({
      selectedGroup: value
    });
  };
  onChangeOffice = value => {
    this.setState({
      selectedOffice: value
    });
  };
  onChangeCanteen = value => {
    this.setState({
      selectedCanteen: value
    });
  };

  onChangeGroupEdit = (value, row_guid) => {
    this.setState(prevState => ({
      selectedToUpdate:
        prevState.selectedToUpdate.row_guid === row_guid
          ? {
              ...this.state.selectedToUpdate,
              row_guid: row_guid,
              editGroup: value
            }
          : {
              row_guid: row_guid,
              editGroup: value
            }
    }));
  };
  onChangeOfficeEdit = (value, row_guid) => {
    this.setState(prevState => ({
      selectedToUpdate:
        prevState.selectedToUpdate.row_guid === row_guid
          ? {
              ...this.state.selectedToUpdate,
              row_guid: row_guid,
              editOffice: value
            }
          : {
              row_guid: row_guid,
              editOffice: value
            }
    }));
  };
  onChangeCanteenEdit = (value, row_guid) => {
    this.setState(prevState => ({
      selectedToUpdate:
        prevState.selectedToUpdate.row_guid === row_guid
          ? {
              ...this.state.selectedToUpdate,
              row_guid: row_guid,
              editCanteen: value
            }
          : {
              row_guid: row_guid,
              editCanteen: value
            }
    }));
  };

  renderFooter = () => {
    return (
      <div style={{ display: "flex", flex: 1, alignContent: "center" }}>
        <Input
          placeholder="Departman adı giriniz ..."
          value={this.state.department_definition}
          onChange={e =>
            this.setState({ department_definition: e.target.value })
          }
          style={{ width: "20%", marginTop: "8px" }}
        />
        <Select
          style={{ width: "23%", padding: "8px" }}
          placeholder="ofis seç"
          onChange={this.onChangeOffice}
          value={this.state.selectedOffice}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {this.state.offices
            ? this.state.offices.map(item => (
                <Option key={item.row_guid}>{item.definition}</Option>
              ))
            : null}
        </Select>
        <Select
          style={{ width: "23%", padding: "8px" }}
          placeholder="grup seç"
          onChange={this.onChangeGroup}
          value={this.state.selectedGroup}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {this.state.groups
            ? this.state.groups.map(item => (
                <Option key={item.row_guid}>{item.definition}</Option>
              ))
            : null}
        </Select>
        <Select
          style={{ width: "23%", marginRight: "8px", marginTop: "8px" }}
          placeholder="yemekhane seç"
          onChange={this.onChangeCanteen}
          value={this.state.selectedCanteen}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {this.state.canteens
            ? this.state.canteens.map(item => (
                <Option key={item.row_guid}>{item.definition}</Option>
              ))
            : null}
        </Select>

        <div style={{ width: "11%", padding: "8px", flex: 1 }}>
          <Button
            onClick={this.insertDepartment}
            type={"primary"}
            style={{ width: "100%", align: "right" }}
          >
            Ekle
          </Button>
        </div>
      </div>
    );
  };

  insertDepartment = () => {
    const {
      department_definition,
      selectedCanteen,
      selectedOffice,
      selectedGroup
    } = this.state;
    department_definition && selectedCanteen && selectedOffice && selectedGroup
      ? Axios.post(
          API_ADDRESS + "/Department",
          {
            definition: department_definition,
            office_row_guid: selectedOffice,
            group_row_guid: selectedGroup,
            canteen_row_guid: selectedCanteen
          },
          {
            headers: {
              Authorization: "Bearer " + this.token
            }
          }
        )
          .then(res => {
            const { data, code } = res.data;
            if (code === 200) {
              data["department_row_guid"] = data.row_guid;
              this.setState({
                data: [...this.state.data, data],
                department_definition: "",
                selectedCanteen: "",
                selectedOffice: "",
                selectedGroup: ""
              });
              message.success(1000, "Ekleme işlemi başarılı");
            } else {
              message.warning(1000, "Kayıt işlemi başarısız");
            }
          })
          .catch(err => {
            message.error(1000, "Hata oluştu.");
          })
      : message.warning(1300, "Doldurmanız gereken alanlar mevcut.");
  };
  deleteDepartment = id => {
    Axios.put(
      API_ADDRESS + "/Department",
      {
        row_guid: id,
        is_deleted: true
      },
      {
        headers: {
          Authorization: "Bearer " + this.token
        }
      }
    )
      .then(res => {
        if (res.data.code === 200) {
          const data = [...this.state.data];
          this.setState({
            data: data.filter(item => item.department_row_guid !== id)
          });
        } else {
          message.warning(1000, res.data.message);
        }
      })
      .catch(err => {
        message.error(1000, err);
      });
  };
  updateDepartment = (defination, id) => {
    const { editCanteen, editOffice, editGroup } = this.state.selectedToUpdate;
    Axios.put(
      API_ADDRESS + "/Department",
      {
        row_guid: id,
        canteen_row_guid: editCanteen,
        office_row_guid: editOffice,
        group_row_guid: editGroup
      },
      {
        headers: {
          Authorization: "Bearer " + this.token
        }
      }
    )
      .then(res => {
        if (res.data.code === 200) {
          message.success(
            1000,
            defination + " adlı kayıt başarılı bir şekilde güncellendir."
          );
        } else {
          message.warning(1000, res.data.message);
        }
      })
      .catch(err => {
        message.error(1000, err);
      });
  };

  componentWillUnmount() {
    this.setState({
      data: [],
      canteen_definition: "",
      offices: {},
      canteens: {},
      groups: {}
    });
  }

  render() {
    const { data, selectedToUpdate } = this.state;
    return (
      <div style={{ display: "flex", flex: 1, margin: 10 }}>
        <TempTable
          columns={this.state.columns}
          data={this.state.data}
          footer={this.renderFooter}
          rowKey={record => record.department_row_guid}
          tableTitle={"DEPARTMANLAR"}
          loading={data ? false : true}
        />
      </div>
    );
  }
}
