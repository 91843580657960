import React, { Component } from "react";
import Text from "antd/lib/typography/Text";
import { Empty, Row, Col, Select, Table, Button, Spin } from "antd";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";
import Axios from "axios";
import { API_ADDRESS } from "../config";
import message from "../helper";
import moment from "moment";

const { Option } = Select;

export default class CancelFoodScreen extends Component {
  state = {
    loading: true,
    selectedUser: {},
    selectedRowKeys: [],
    dataSource: [],
    columns: [
      {
        title: "Tarih",
        dataIndex: "reserved_date",
        width: "100%",
        render: (text, record) => {
          return moment(record.reserved_date).format("DD.MM.YYYY HH:mm");
        }
      }
    ]
  };
  user = SessionStorageDecrypt("user");
  selectableUser = [];

  renderEmpty() {
    return (
      <Empty
        style={{ marginTop: "35%" }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{
          height: 60
        }}
        description={<span>Yukarıdan ilgili kişiyi seçiniz.</span>}
      />
    );
  }

  onChange(value) {
    let selectedUser = this.selectableUser.find(x => x.key === value);
    Axios.get(
      API_ADDRESS + "/Account/" + selectedUser.props.user.user_row_guid,
      {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
    ).then(res => {
      this.setState({
        dataSource: res.data.data.user_not_used_reserved_food_days_manager
      });
    });
    this.setState({ selectedUser: selectedUser.props.user });
  }

  componentDidMount() {
    Axios.get(API_ADDRESS + "/Account", {
      headers: {
        Authorization: "Bearer " + this.user.token
      }
    }).then(res => {
      res.data.data.map(user => {
        this.selectableUser.push(
          <Option key={user.user_row_guid} user={user}>
            {user.full_name}
          </Option>
        );
      });
      this.setState({ loading: false });
    });
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  cancelCredit = () => {
    Axios.post(
      API_ADDRESS + "/Food/ReloadCredit",
      {
        user_row_guid: this.state.selectedUser.user_row_guid,
        reserved_dates: this.state.selectedRowKeys
      },
      {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
    ).then(res => {
      if (res.data.code === 200) {
        message.success(1000, "İade işlemi başarılı");
        this.setState({
          dataSource: res.data.data.user_not_used_reserved_food_days_manager
        });
      } else {
        message.warning(1000, "Hata oluştu");
      }
    });
  };

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        {
          key: "all-data",
          text: "Tüm veriyi seç.",
          onSelect: () => {
            this.setState({
              selectedRowKeys: [...this.state.foodData.keys()] // 0...45
            });
          }
        }
      ]
    };
    return (
      <div>
        {this.state.loading ? (
          <Spin />
        ) : (
          <div>
            <b
              style={{
                fontFamily: "Avenir",
                textAlign: "left",
                color: "rgb(227, 10, 23)",
                alignContent: "center",
                fontSize: 20
              }}
            >
              {" "}
              Bir sonraki gün için iptal işlemi en geç 16:45' da
              yapılabilmektedir.
            </b>
            <Row gutter={[20, 20]}>
              <Col
                xs={24}
                sm={24}
                md={10}
                lg={8}
                xl={6}
                style={styles.searchBox}
              >
                <Text style={styles.textStyle}>KİŞİLERİ SEÇİN</Text>
                <Row>
                  <Select
                    locale={{
                      emptyText: <span>Veri yok</span>
                    }}
                    size="large"
                    showSearch
                    style={{ width: "100%", marginBottom: "10px" }}
                    placeholder="Aramak için tıklayın."
                    optionFilterProp="children"
                    onChange={this.onChange.bind(this)}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.selectableUser}
                  </Select>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                <Table
                  locale={{
                    emptyText: <span>Veri yok</span>
                  }}
                  style={styles.maximizeScreen}
                  rowSelection={rowSelection}
                  columns={this.state.columns}
                  rowKey={rowKey => rowKey.reserved_date}
                  dataSource={this.state.dataSource}
                />
                <Button onClick={this.cancelCredit}>Kredi İade Et</Button>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  cardHolder: {
    width: "100%",
    height: "100%",
    backgroundColor: "white"
  },
  textStyle: {
    fontWeight: "800",
    color: "#578ebe",
    fontSize: "15px",
    marginBottom: "10px"
  },
  searchBox: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    height: "100%"
  },
  maximizeScreen: {
    background: "white",
    width: "100%",
    height: "100%"
  }
};
