import React, { Component } from "react";
import { Menu, Icon, Layout } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import authActions from "../actions/authAction";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";

const { Header } = Layout;
const { SubMenu } = Menu;
const { logout } = authActions;

class MyHeader extends Component {
  state = {
    current: "home",
    user: {}
  };

  handleClick = e => {
    this.setState({
      current: e.key
    });
  };

  componentDidMount() {
    let user = this.props.user;
    this.setState({ user: user });
  }

  render() {
    return (
      <Header>
        <section className="vd-header-top">
          <div className="bayrak"></div>
          <div className="atam"></div>
          <div className="header-top">
            <a href="/main" title="Ana Sayfa" rel="home" className="logo">
              <img src={require("../images/logo.png")} alt="Ana Sayfa" />
            </a>
          </div>
        </section>
        <Menu
          className="top-menu"
          onClick={this.handleClick.bind(this)}
          selectedKeys={[this.state.current]}
          style={{ display: "flex", justifyContent: "flex-start" }}
          mode="horizontal"
        >
          <Menu.Item>
            <Link to="/main">
              <Icon type="home" /> Anasayfa
            </Link>
          </Menu.Item>

          {/*<SubMenu key="sub1" title={<span>Mutemet</span>}>
            <Menu.Item key="setting:1">
              <Link to="/workerDetail">Personel Kart Detayları</Link>
            </Menu.Item>
            </SubMenu>*/}
          {this.state.user.role === 8 ? (
            <SubMenu key="yonetim" title={<span>Yönetim</span>}>
              <Menu.Item key="updaterWorkers">
                <Link to="/updateWorkers">Personel Bilgileri</Link>
              </Menu.Item>
              <Menu.Item key="sendSMS">
                <Link to="/sendSMS">SMS Gönderim İşlemleri</Link>
              </Menu.Item>
              <Menu.Item key="cancelFood">
                <Link to="/cancelFood">Kredi İade İşlemleri </Link>
              </Menu.Item>
              <Menu.Item key="usedReports">
                <Link to="/usedReports">Kullanım Raporları </Link>
              </Menu.Item>
              <Menu.Item key="spendingReports">
                <Link to="/SpendingReports">Harcama Yükleme Raporları </Link>
              </Menu.Item>
              <Menu.Item key="manualPassReports">
                <Link to="/manualPassReports">
                  Manuel Turnike Geçiş Raporları
                </Link>
              </Menu.Item>
              <Menu.Item key="summaryPassReports">
                <Link to="/summaryReports">Günlük Toplam Geçiş Raporu</Link>
              </Menu.Item>
              <Menu.Item key="departmantCumulativeReport">
                <Link to="/departmantCumulativeReport">
                  Departman Bazında Kullanım Sayıları
                </Link>
              </Menu.Item>

              <Menu.Item key="canteenCumulativeReport">
                <Link to="/canteenCumulativeReport">
                  Yemekhane Bazında Kullanım Sayıları
                </Link>
              </Menu.Item>
              <Menu.Item key="departmantAndCanteenReports">
                <Link to="/departmantAndCanteenReports">
                  Gün Bazında Departman/Yemekhane Kırılım Raporu
                </Link>
              </Menu.Item>
              <Menu.Item key="DepartmantAndPersonelReports">
                <Link to="/DepartmantAndPersonelReports">
                  Günlük Rezervasyon Raporu
                </Link>
              </Menu.Item>
            </SubMenu>
          ) : null}

          {this.state.user.role === 8 || this.state.user.role === 7 ? (
            <SubMenu key="yemekhane" title={<span>Yemekhane</span>}>
              <Menu.Item key="menu">
                <Link to="/menu">Yemek Listesi Görüntüleme</Link>
              </Menu.Item>
              <Menu.Item key="mealList">
                <Link to="/meallist">Yemek Listesi Güncelleme </Link>
              </Menu.Item>
              <Menu.Item key="reservedFoodCount">
                <Link to="/reservedFoodCount">Güncel Yemek Talep Listesi </Link>
              </Menu.Item>
              <Menu.Item key="manualPassReportsy">
                <Link to="/manualPassReports">
                  Manuel Turnike Geçiş Raporları
                </Link>
              </Menu.Item>
              <Menu.Item key="summaryPassReportsy">
                <Link to="/summaryReports">Günlük Toplam Geçiş Raporu</Link>
              </Menu.Item>

              <Menu.Item key="canteenCumulativeReportyemekhane">
                <Link to="/canteenCumulativeReport">
                  Yemekhane Bazında Kullanım Sayıları
                </Link>
              </Menu.Item>
              <Menu.Item key="departmantAndCanteenReports">
                <Link to="/departmantAndCanteenReports">
                  Gün Bazında Departman/Yemekhane Kırılım Raporu
                </Link>
              </Menu.Item>
              <Menu.Item key="DepartmantAndPersonelReports">
                <Link to="/DepartmantAndPersonelReports">
                  Günlük Rezervasyon Raporu
                </Link>
              </Menu.Item>
             
            </SubMenu>
          ) : null}

          {this.state.user.role === 8 ? (
            <SubMenu key="sub5" title={<span>Tanımlamalar</span>}>
              <Menu.Item key="group">
                <Link to="group">Grup Tanımlama</Link>
              </Menu.Item>
              <Menu.Item key="canteen">
                <Link to="canteen">Yemekhane Tanımlama</Link>
              </Menu.Item>
              <Menu.Item key="office">
                <Link to="office">Daire Tanımlama</Link>
              </Menu.Item>
              <Menu.Item key="department">
                <Link to="Department">Departman Tanımlama</Link>
              </Menu.Item>
              <Menu.Item key="food">
                <Link to="food">Yemek Fiyat Tanımlama</Link>
              </Menu.Item>
              <Menu.Item key="job">
                <Link to="job">Görev Tanımlama</Link>
              </Menu.Item>
              <Menu.Item key="addProfileScreen">
                <Link to="/addProfileScreen">Geçiş Profili Ekle </Link>
              </Menu.Item>
            </SubMenu>
          ) : null}
          <SubMenu key="creditOperation" title={<span>Kredi İşlemleri</span>}>
            <Menu.Item key="setting:2" style={{ align: "right" }}>
              <Link to="/payment">Yemek Satın Al</Link>
            </Menu.Item>

            <Menu.Item key="refundbyuser" style={{ align: "right" }}>
              <Link to="/cancelfoodbyuser">İade et</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub6"
            title={<span>Hoşgeldiniz {this.props.user.full_name}</span>}
            style={{
              marginLeft: "auto",
              marginRight: "5%"
            }}
          >
            <img
              alt="profil_photo"
              style={{
                width: "60%",
                marginLeft: "50%",
                transform: "translate(-50%)",
                borderRadius: "50%",
                marginTop: "10%",
                objectFit: "cover",
                objectPosition: "50% 0"
              }}
              src={"data:image/jpeg;base64, " + this.props.user.profile_picture}
            />
            <Menu.Item
              key="name"
              style={{ textAlign: "center", fontWeight: "600" }}
            >
              <Link to="/profile"> {this.props.user.full_name}</Link>
            </Menu.Item>
            <Menu.Item key="yemekhakki">
              <Link to="/payment">
                Kullanılabilir Kredi: {this.props.user.current_credit}
              </Link>
            </Menu.Item>
            <Menu.Item key="rez">
              <Link to="/reservation">
                Rezarvasyonlarım:{" "}
                {this.props.user.user_reserved_food_days
                  ? Object.entries(this.props.user.user_reserved_food_days)
                      .length
                  : null}
              </Link>
            </Menu.Item>
            <Menu.Item key="purchaseHistory">
              <Link to="/purchaseHistory">Satın Alma Geçmişim</Link>
            </Menu.Item>
            <Menu.Item key="profile">
              <Link to="/profile">Profili Güncelle</Link>
            </Menu.Item>
            <Menu.Item key="bb">
              <Link to="/" onClick={this.props.logout}>
                Çıkış
              </Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Header>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  const { userData } = Auth;
  return {
    user: userData
  };
};

export default connect(mapStateToProps, { logout })(MyHeader);
