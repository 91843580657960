import React, { Component } from 'react'
import { Table } from 'antd'
import Axios from 'axios';
import { SessionStorageDecrypt } from '../sessionStorageSecurty';
import moment from 'moment';

export default class ReservationScreen extends Component {
    state = {
        dataSource: [],
        canSave: false,
        columns: [
            {
                width: "20%",
                title: "Tarih",
                dataIndex: "reserved_date",
                ellipsis: false,
                render:(text,record)=>{
                    return moment(record.reserved_date).format("DD.MM.YYYY")
                }
            },
            {
                title: "Yemek",
                width: "80%",
                dataIndex: "menu",
                ellipsis: true
            }
        ]
    };
    componentDidMount() {
        this.setState({
            dataSource: SessionStorageDecrypt("user").user_reserved_food_days
        })
    }
    render() {
        return (
            <div>
                <Table
                    columns={this.state.columns}
                    dataSource={this.state.dataSource}
                    rowKey={record => record.row_guid}
                />
            </div>
        )
    }
}
