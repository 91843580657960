import React, { Component } from "react";
import { Row, Col, Select, Button, Icon, Modal, Table } from "antd";
import Text from "antd/lib/typography/Text";
import SpendingTable from "../Component/SpendingTable";
import { withRouter } from "react-router-dom";
import ProfileCard from "../Component/ProfileCard";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";
import { AxiosGet } from "../helper/Axios";
import ButtonGroup from "antd/lib/button/button-group";
import moment from "moment";
const { Option } = Select;

export default class UpdateWorkersScreen extends Component {
  state = {
    children: [],
    loading: true,
    selectedUser: {},
    visible: false,
    userData: [],
    columns: [
      {
        width: "20%",
        title: "Tarih",
        dataIndex: "reserved_date",
        ellipsis: false,
        render: (text, record) => {
          return moment(record.reserved_date).format("DD.MM.YYYY");
        }
      },
      {
        title: "Yemek",
        width: "80%",
        dataIndex: "menu",
        ellipsis: true
      }
    ]
  };

  onChange(value) {
    let selectedUser = this.state.userData.find(x => x.user_row_guid === value);
    this.setState({ selectedUser: selectedUser });
  }

  componentDidMount() {
    this.getUser();
  }

  getUser() {
    this.setState({
      userData: []
    });
    AxiosGet("/account").then(res => {
      this.setState({ userData: res, loading: false });
    });
  }

  user = SessionStorageDecrypt("user");

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = e => {
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    this.getUser();
    this.setState({
      visible: false
    });
  };

  updateChildren = newuser => {
    const temp = this.state.userData;
    temp.map(data => {
      return data.user_row_guid === newuser.row_guid ? newuser : data;
    });
    this.setState({
      userData: temp
    });
  };

  render() {
    const renderSelect = this.state.userData.map(user => {
      return (
        <Option key={user.user_row_guid} user={user}>
          {user.full_name}
        </Option>
      );
    });
    return (
      <div>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} style={styles.searchBox}>
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <Text style={styles.textStyle}>KİŞİLERİ SEÇİN</Text>
              <Select
                disabled={this.state.loading}
                loading={this.state.loading}
                locale={{
                  emptyText: <span>Veri yok</span>
                }}
                size="large"
                showSearch
                style={{
                  width: "50%",
                  marginTop: "10px",
                  marginBottom: "10px"
                }}
                placeholder="Aramak için tıklayın."
                optionFilterProp="children"
                onChange={this.onChange.bind(this)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {/*this.state.children*/}
                {renderSelect}
              </Select>

              <Button
                loading={this.state.loading}
                style={styles.textStyle}
                onClick={this.showModal}
              >
                Kişi Ekle
              </Button>
              <Button
                disabled={
                  Object.entries(this.state.selectedUser).length === 0
                    ? true
                    : false
                }
                style={styles.textStyle}
                onClick={() => {
                  AxiosGet(
                    "/account/" + this.state.selectedUser.user_row_guid
                  ).then(res => {
                    this.setState({ tabledata: res.user_reserved_food_days });
                    this.setState({ showRezervations: true });
                  });
                }}
              >
                Rezervasyonlarını Gör
              </Button>
            </Row>

            <Row style={styles.profileBox}>
              <Modal
                width={1000}
                centered
                title="Kişi Ekle"
                footer={null}
                onCancel={this.handleCancel}
                visible={this.state.visible}
              >
                <ProfileCard
                  history={this.props.history}
                  getUser={this.getUser}
                  modalCancel={this.handleCancel}
                  save
                  is_admin={true}
                  token={this.user.token}
                />
              </Modal>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: 0 }}>
            <ProfileCard
              history={this.props.history}
              hide={
                Object.entries(this.state.selectedUser).length === 0
                  ? true
                  : false
              }
              user={this.state.selectedUser}
              is_admin={true}
              token={this.user.token}
              refresh={() => {
                this.getUser();
              }}
            />
          </Col>
        </Row>
        <Modal
          visible={this.state.showRezervations}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              key="back"
              onClick={() => {
                this.setState({ showRezervations: false });
              }}
            >
              Tamam
            </Button>
          ]}
        >
          <Table
            locale={{
              emptyText: <span>Veri yok</span>
            }}
            columns={this.state.columns}
            dataSource={this.state.tabledata}
            rowKey={record => record.row_guid}
          />
        </Modal>
      </div>
    );
  }
}

const styles = {
  cardHolder: {
    width: "100%",
    height: "100%",
    backgroundColor: "white"
  },
  textStyle: {
    width: "50%",
    fontWeight: "800",
    color: "#578ebe",
    fontSize: "15px",
    marginBottom: "10px"
  },
  searchBox: {
    marginBottom: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    backgroundColor: "white",
    height: "100%"
  },
  profileBox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    backgroundColor: "white",
    height: "100%"
  }
};
