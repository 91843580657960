import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import LoginScreen from "./Screens/LoginScreen";
import { createStore, applyMiddleware } from 'redux';
import { Provider } from "react-redux";
import rootReducer from "./reducers"
import thunk from "redux-thunk";
import Main from "./Component/Main";
import Boot from "./boot";
import PurchaseScreen from "./Screens/PurchaseScreen";
import ResponsePurchaseScreen from "./Screens/ResponsePurchaseScreen";
import ForgotPasswordScreen from "./Screens/ForgotPasswordScreen";


const RestrictedRoute = ({ component: Component, isLoggedIn, }) => (
    <Route
      render={props =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: '/signin',
              }}
            />
          )
      }
    />
  );

const Routes = ({ history, isLoggedIn }) => {
    return (
        <ConnectedRouter history={history}>
            <div>
                <Switch>
                    <Route path="/signin" component={LoginScreen}></Route>
                    <Route path="/forgotPasword" component={ForgotPasswordScreen}></Route>
                    <Route path="/purchase" component={PurchaseScreen}></Route>
                    <Route path="/Payment/Check" component={ResponsePurchaseScreen} axact ></Route>
                    <RestrictedRoute
                        path="/"
                        component={Main}
                        isLoggedIn={isLoggedIn}
                    />
                    <Route
                        component={LoginScreen}
                    />
                </Switch>
            </div>
        </ConnectedRouter>
    );
};

const mapStateToProps = ({ Auth }) => {
    const { userData } = Auth;
    return {
        isLoggedIn: userData ? true : false
    };
};

export default connect(mapStateToProps)(Routes);
