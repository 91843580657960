import React, { Component } from 'react'
import { Result, Button } from 'antd'

export default class NotFoundPage extends Component {
    render() {
        return (
            <div>
                <Result
                    status="404"
                    title="404"
                    subTitle="Üzgünüz bu sayfaya ulaşılamıyor. Sayfa bulunmamaktadır veya sayfa silinmiştir."
                    extra={<Button type="danger" href={"/main"}>Anasayfaya Dön</Button>}
                />,
            </div>
        )
    }
}
