import React, { Component } from "react";
import { Button, Checkbox, Form, Input, Icon, Spin } from "antd";
import authActions from "../actions/authAction";
import { connect } from "react-redux";

const { login } = authActions;

class LoginScreen extends Component {
  state = {};

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      var { identity_number, password } = values;
      identity_number = identity_number ? identity_number : "";
      password = password ? password : "";
      this.props.login(identity_number, password);
    });
  };

  render() {
    const { history, isLoggedIn } = this.props;
    if (isLoggedIn) {
      history.push("/main");
    }
    const { getFieldDecorator } = this.props.form;
    return (
      <div style={styles.containerStyle}>
        <Spin
          indicator={
            <Icon
              type="loading"
              style={{ fontSize: 30, color: "#bd4d50" }}
              spin
            />
          }
          spinning={this.props.loading}
        >
          <Form
            onSubmit={this.handleSubmit}
            className="login-form"
            style={styles.loginContainerStyle}
          >
            <img
              alt="img"
              src={require("../images/login_logo.jpg")}
              style={styles.logoStyle}
            ></img>
            <Form.Item>
              {getFieldDecorator("identity_number", {
                rules: [
                  { required: true, message: "Lütfen kullanıcı adınızı girin!" }
                ]
              })(
                <Input
                  size="large"
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="TC Kimlik"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("password", {
                rules: [{ required: true, message: "Lütfen şifrenizi girin!" }]
              })(
                <Input.Password
                  size="large"
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Şifre"
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button
                loading={this.props.loading}
                size="large"
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ width: "100%" }}
              >
                Giriş Yap
              </Button>
              <Button
                className="login-form-forgot"
                onClick={() => {
                  this.props.history.push("/forgotPasword");
                }}
                style={{
                  float: "right",
                  borderRadius: "0px",
                  borderWidth: "0px"
                }}
              >
                Şifremi unuttum
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    );
  }
}

const styles = {
  containerStyle: {
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "flex",
    flex: 1,
    height: "100vh",
    overFlowY: "hidden",
    alignItems: "center",
    justifyContent: "center",
    background:
      "radial-gradient(circle farthest-corner at center,rgba(54, 57, 64, 0) 0,rgba(54, 57, 64, .8) 100%),#FFF"
  },
  loginContainerStyle: {
    backgroundColor: "white",
    padding: "20px",
    maxWidth: "400px",
    borderRadius: "2%"
  },
  logoStyle: {
    position: "abdolute",
    width: "40%",
    marginBottom: "20px",
    marginLeft: "50%",
    transform: "translate(-50%)"
  }
};

const mapStateToProps = ({ Auth }) => {
  const { userData, loading, failed } = Auth;
  return {
    isLoggedIn: userData ? true : false,
    userData,
    loading,
    failed
  };
};

const WrampedLogin = Form.create()(LoginScreen);
export default connect(mapStateToProps, { login })(WrampedLogin);
