import Swal from "sweetalert2"


const message = {
    success: (time, title,button) => {
        Swal.fire({
            title: title,
            timer: time?time:1000,
            icon: 'success',
            showConfirmButton: button?button:false,
        }).then((result) => {
            /* Read more about handling dismissals below 
            if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer')
            }*/
        })
    },
    successb: (title) => {
        Swal.fire({
            title: title,
            icon: 'success',
        }).then((result) => {
            /* Read more about handling dismissals below 
            if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer')
            }*/
        })
    },
    warning: (time, title) => {
        Swal.fire({
            title: title,
            timer: time?time:1000,
            icon: 'warning',
            showConfirmButton: false,
        }).then((result) => {
            /* Read more about handling dismissals below 
            if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer')
            }*/
        })
    },
    error: (time, title) => {
        Swal.fire({
            title: title?title:"İşlem Başarısız",
            timer: time?time:1000,
            icon: 'error',
            showConfirmButton: false,
        }).then((result) => {
            /* Read more about handling dismissals below 
            if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer')
            }*/
        })
    }
}

export default message;