import authActions from "../actions/authAction";

const initState = {
  userData: null,
  loading: false,
  failed: false
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case authActions.LOGIN:
      return {
        ...state,
        loading: true
      };
    case authActions.LOGIN_USER_SUCCESS:
      return {
        ...initState,
        userData: action.payload
      };
    case authActions.LOGOUT:
      return {
        ...initState,
        userData: null
      };
    case authActions.UPDATE_PROPS:
      return {
        ...state,
        userData: action.payload
      };
    case authActions.LOGIN_ERROR:
      return {
        ...state,
        failed: true,
        loading: false
      };
    default:
      return state;
  }
}
