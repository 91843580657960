import React, { Component } from "react";
import { Table, Row, Col, Select, Button, DatePicker, Input, Icon } from "antd";
import Axios from "axios";
import { API_ADDRESS } from "../config";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";
import moment from "moment";
import Highlighter from "react-highlight-words";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default class SpendingReports extends Component {
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            width: 188,
            marginBottom: 8,
            display: "block"
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Ara
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Sıfırla
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  state = {
    departments: [],
    jobs: [],
    offices: [],
    users: [],
    selected_department: "",
    selected_office: "",
    selected_user: "",
    selected_job: "",
    start_date: "",
    end_date: "",
    filterData: [],
    columns: [
      {
        title: "İşlem Tarihi ",
        dataIndex: "operation_date",
        align: "left",
        width: "20%",
        ...this.getColumnSearchProps("operation_date"),
        sorter: (a, b) =>
          moment(a.operation_date).unix() - moment(b.operation_date).unix(),
        render: (text, record) => {
          moment.locale("tr");
          return moment(record.operation_date).format("DD.MM.YYYY HH:mm");
        }
      },
      {
        title: "İşlem Adedi ",
        dataIndex: "operation_credit",
        align: "left",
        width: "20%"
      },
      {
        title: "İşlem Öncesi Kredi Miktarı",
        dataIndex: "credit_before_operation",
        align: "left",
        width: "20%"
      },
      {
        title: "İşlem Sonrası Kredi Miktarı",
        dataIndex: "credit_after_operation",
        align: "left",
        width: "20%"
      },
      {
        title: "Açıklama",
        dataIndex: "operation_type_definition",
        align: "left",
        width: "20%"
      }
    ]
  };
  componentDidMount() {
    Axios.get(API_ADDRESS + "/Report/Filters", {
      headers: {
        Authorization: "Bearer " + SessionStorageDecrypt("user").token
      }
    }).then(res => {
      const { users } = res.data.data;
      this.setState({
        users
      });
    });
  }

  renderUsers = () => {
    return (
      <Select
        showSearch
        optionFilterProp="children"
        style={{ width: 200 }}
        placeholder="Kullanıcı Seç"
        allowClear
        onChange={value => {
          this.setState({
            selected_user: value
          });
        }}
        filterOption={(input, option) => {
          return (
            option.props.children
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          );
        }}
      >
        {this.state.users.map(item => (
          <Option key={item.user_row_guid}> {item.full_name} </Option>
        ))}
      </Select>
    );
  };

  filter = () => {
    const { selected_user } = this.state;
    Axios.post(
      API_ADDRESS + "/Report/User",
      {
        selected_user
      },
      {
        headers: {
          Authorization: "Bearer " + SessionStorageDecrypt("user").token
        }
      }
    ).then(res => {
      this.setState({
        filterData: res.data.data
      });
    });
  };

  onChange = (value, dateString) => {
    this.setState({
      start_date: dateString[0],
      end_date: dateString[1]
    });
  };

  render() {
    return (
      <div>
        <Row gutter={[12, 12]}>
          <Col lg={8}>
            <div
              style={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <b>Kişi</b>

              {this.renderUsers()}
            </div>
          </Col>
          <Col lg={4}>
            <div
              style={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <b>
                <br></br>
              </b>
              <Button onClick={this.filter}> Uygula </Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Table
            locale={{
              emptyText: <span>Veri yok</span>
            }}
            columns={this.state.columns}
            dataSource={this.state.filterData}
            rowKey={record => record.operation_date}
          />
        </Row>
      </div>
    );
  }
}
