import React, { Component } from "react";
import TempTable from "../Component/common/TempTable";
import message from "../helper";
import Axios from "axios";
import { API_ADDRESS } from "../config";
import { Button, Input, Select, Icon } from "antd";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";
import Highlighter from "react-highlight-words";
const { Option } = Select;

export default class Food extends Component {
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            width: 188,
            marginBottom: 8,
            display: "block"
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Ara
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Sıfırla
        </Button>
      </div>
    ),

    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  token = SessionStorageDecrypt("user").token;
  state = {
    searchText: "",
    searchedColumn: "",
    data: null,
    food_definition: "",
    insertPersonelPrice: "",
    insertGevornmentPrice: "",
    selectedToUpdate: {
      editGevornmentPrice: "",
      editPersonelPrice: "",
      row_guid: ""
    },
    columns: [
      {
        title: "Tip",
        dataIndex: "definition",
        width: "25%",
        align: "left",
        ...this.getColumnSearchProps("definition")
      },
      {
        title: "Devlet",
        dataIndex: "government_support_price",
        width: "30%",
        align: "left",
        render: (text, record) => {
          return (
            <Input
              style={{ textAlign: "right" }}
              value={
                this.state.selectedToUpdate.row_guid === record.row_guid
                  ? this.state.selectedToUpdate.editGevornmentPrice
                  : record.government_support_price
              }
              onChange={e => {
                const { value } = e.target;
                const reg = /^-?[0-9]*(\.[0-9]*)?$/;
                if (
                  (!isNaN(value) && reg.test(value)) ||
                  value === "" ||
                  value === "-"
                ) {
                  this.setState({
                    selectedToUpdate:
                      this.state.selectedToUpdate.row_guid === record.row_guid
                        ? {
                            ...this.state.selectedToUpdate,
                            row_guid: record.row_guid,
                            editGevornmentPrice: e.target.value.toString()
                          }
                        : {
                            editGevornmentPrice: e.target.value.toString(),
                            editPersonelPrice: record.personel_price,
                            row_guid: record.row_guid
                          }
                  });
                }
              }}
              suffix="₺"
            />
          );
        }
      },
      {
        title: "Personel",
        dataIndex: "personel_price",
        width: "25%",
        align: "left",
        render: (text, record) => {
          return (
            <Input
              style={{ textAlign: "right" }}
              value={
                this.state.selectedToUpdate.row_guid === record.row_guid
                  ? this.state.selectedToUpdate.editPersonelPrice
                  : record.personel_price
              }
              onChange={e => {
                const { value } = e.target;
                const reg = /^-?[0-9]*(\.[0-9]*)?$/;
                if (
                  (!isNaN(value) && reg.test(value)) ||
                  value === "" ||
                  value === "-"
                ) {
                  this.setState({
                    selectedToUpdate:
                      this.state.selectedToUpdate.row_guid === record.row_guid
                        ? {
                            ...this.state.selectedToUpdate,
                            row_guid: record.row_guid,
                            editPersonelPrice: e.target.value.toString()
                          }
                        : {
                            editPersonelPrice: e.target.value.toString(),
                            editGevornmentPrice:
                              record.government_support_price,
                            row_guid: record.row_guid
                          }
                  });
                }
              }}
              suffix="₺"
            />
          );
        }
      },
      {
        title: "İşlemler",
        key: "action",
        align: "left",
        width: "20%",
        render: (text, record) => {
          return (
            <div style={{ display: "flex", flex: 1 }}>
              <Button
                type={"primary"}
                onClick={() => {
                  this.deleteFood(record.row_guid);
                }}
                style={{ width: "50%" }}
              >
                Sil
              </Button>
              <Button
                disabled={
                  this.state.selectedToUpdate.row_guid === record.row_guid
                    ? false
                    : true
                }
                onClick={() => {
                  this.updateFood(record.definition, record.row_guid);
                }}
                style={{ width: "50%", color: "black" }}
              >
                Güncelle
              </Button>
            </div>
          );
        }
      }
    ]
  };

  componentDidMount() {
    Axios.get(API_ADDRESS + "/Food/Price", {
      headers: {
        Authorization: "Bearer " + this.token
      }
    }).then(res => {
      if (res.data.code === 200) {
        this.setState({
          data: res.data.data
        });
      } else {
        this.setState({ loading: false });
      }
    });
  }

  onChangeGevornment = value => {
    this.setState({
      insertGevornment: value
    });
  };

  onChangePersonel = value => {
    this.setState({
      insertPersonel: value
    });
  };
  renderFooter = () => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          alignContent: "center"
        }}
      >
        <Input
          placeholder="Yemek tipi giriniz ..."
          value={this.state.food_definition}
          onChange={e => this.setState({ food_definition: e.target.value })}
          style={{ width: "25%", marginRight: "8px" }}
        />
        <Input
          style={{ width: "29%", marginRight: "8px" }}
          placeholder="Devlet fiyat"
          onChange={e => {
            const { value } = e.target;
            const reg = /^-?[0-9]*(\.[0-9]*)?$/;
            if (
              (!isNaN(value) && reg.test(value)) ||
              value === "" ||
              value === "-"
            ) {
              this.setState({
                insertGevornmentPrice: e.target.value
              });
            }
          }}
          value={this.state.insertGevornmentPrice}
        />
        <Input
          style={{
            width: "24%",
            marginLeft: "8px",
            marginRight: "8px"
          }}
          placeholder="Personel fiyat"
          onChange={e => {
            const { value } = e.target;
            const reg = /^-?[0-9]*(\.[0-9]*)?$/;
            if (
              (!isNaN(value) && reg.test(value)) ||
              value === "" ||
              value === "-"
            ) {
              this.setState({
                insertPersonelPrice: e.target.value
              });
            }
          }}
          value={this.state.insertPersonelPrice}
        />

        <div
          style={{
            width: "20%",
            marginLeft: "7px",
            marginRight: "8px",
            display: "flex",
            flex: 1,
            justifyContent: "flex-end"
          }}
        >
          <Button
            type="primary"
            onClick={this.insertFood}
            style={{ width: "100%", align: "right" }}
          >
            Ekle
          </Button>
        </div>
      </div>
    );
  };

  insertFood = () => {
    const {
      food_definition,
      insertPersonelPrice,
      insertGevornmentPrice
    } = this.state;
    food_definition && insertPersonelPrice && insertGevornmentPrice
      ? Axios.post(
          API_ADDRESS + "/Food/Price",
          {
            definition: food_definition,
            government_support_price: insertGevornmentPrice,
            personel_price: insertPersonelPrice
          },
          {
            headers: {
              Authorization: "Bearer " + this.token
            }
          }
        )
          .then(res => {
            const { data, code } = res.data;
            if (code === 200) {
              this.setState({
                data: [...this.state.data, data],
                insertPersonelPrice: "",
                insertGevornmentPrice: "",
                food_definition: ""
              });
              message.success(1000, "Ekleme işlemi başarılı");
            } else {
              message.warning(1000, res.data.message);
            }
          })
          .catch(err => {
            message.error();
          })
      : message.warning(1300, "Doldurmanız gereken alanlar mevcut.");
  };
  deleteFood = id => {
    Axios.put(
      API_ADDRESS + "/Food/Price",
      {
        row_guid: id,
        is_deleted: true
      },
      {
        headers: {
          Authorization: "Bearer " + this.token
        }
      }
    )
      .then(res => {
        if (res.data.code === 200) {
          const data = [...this.state.data];
          message.success(1000, "İşlem başarılı");
          this.setState({
            data: data.filter(item => item.row_guid !== id)
          });
        } else {
          message.warning(1000, res.data.message);
        }
      })
      .catch(err => {
        message.error(1000, err);
      });
  };

  updateFood = (definition, id) => {
    const {
      editGevornmentPrice,
      editPersonelPrice
    } = this.state.selectedToUpdate;

    const parseGovernmentPrice = parseFloat(editGevornmentPrice);
    const parsePersonelPrice = parseFloat(editPersonelPrice);
    Axios.put(
      API_ADDRESS + "/Food/Price",
      {
        row_guid: id,
        government_support_price: parseGovernmentPrice,
        personel_price: parsePersonelPrice
      },
      {
        headers: {
          Authorization: "Bearer " + this.token
        }
      }
    )
      .then(res => {
        if (res.data.code === 200) {
          message.success(
            2000,
            definition + " yemek tipi başarılı bir şekilde güncellendi."
          );
        } else {
          message.warning(1500, res.data.message);
        }
      })
      .catch(err => {
        message.error();
      });
  };

  render() {
    const { data, selectedToUpdate } = this.state;
    return (
      <div style={{ display: "flex", flex: 1, margin: 10 }}>
        <TempTable
          columns={this.state.columns}
          data={this.state.data}
          footer={this.renderFooter}
          rowKey={record => record.row_guid}
          tableTitle={"Yemek Tipleri"}
          loading={data ? false : true}
        />
      </div>
    );
  }
}
