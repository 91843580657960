import React, { Component } from "react";
import TempTable from "../Component/common/TempTable";
import message from "../helper";
import Axios from "axios";
import { API_ADDRESS } from "../config";
import { Button, Input } from "antd";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";

export default class Canteen extends Component {
  token = SessionStorageDecrypt("user").token;
  state = {
    columns: [
      {
        title: "Yemekhane Adı",
        dataIndex: "definition",
        width: "90%"
      },
      {
        title: "İşlemler",
        key: "action",
        align: "center",
        width: "10%",
        render: (text, record) => {
          return (
            <Button
              onClick={() => {
                this.deleteCanteen(record.row_guid, record.id);
              }}
              style={{ width: "100%" }}
            >
              Sil
            </Button>
          );
        }
      }
    ],
    data: [],
    canteen_definition: ""
  };
  componentDidMount() {
    Axios.get(API_ADDRESS + "/Canteen", {
      headers: {
        Authorization: "Bearer " + this.token
      }
    }).then(res => {
      this.setState({
        data: res.data.data.filter(data => (data.is_active = true))
      });
    });
  }

  renderFooter = () => {
    return (
      <div style={{ display: "flex", flex: 1, alignContent: "center" }}>
        <Input
          placeholder="Yemekhane adı giriniz ..."
          value={this.state.canteen_definition}
          onChange={e => this.setState({ canteen_definition: e.target.value })}
          style={{ width: "90%", marginRight: "10px" }}
        />
        <div
          style={{
            width: "10%",
            display: "flex",
            flex: 1,
            justifyContent: "flex-end"
          }}
        >
          <Button
            type="primary"
            onClick={this.insertCanteen}
            style={{ align: "right", width: "100%", marginRight: "10px" }}
          >
            Ekle
          </Button>
        </div>
      </div>
    );
  };

  insertCanteen = () => {
    const definition = this.state.canteen_definition;
    if (definition !== "" && definition !== null) {
      Axios.post(
        API_ADDRESS + "/Canteen",
        {
          definition
        },
        {
          headers: {
            Authorization: "Bearer " + this.token
          }
        }
      )
        .then(res => {
          const { data, code } = res.data;
          if (code) {
            this.setState({
              data: [...this.state.data, data],
              canteen_definition: ""
            });
            message.success(1000, "Ekleme işlemi başarılı");
          } else {
            message.warning("Kayıt işlemi başarısız");
          }
        })
        .catch(err => {
          message.error("Hata oluştu.");
        });
    } else {
      message.error("Kantin adı boş olamaz");
    }
  };

  deleteCanteen = (row_guid, id) => {
    Axios.put(
      API_ADDRESS + "/Canteen",
      {
        row_guid,
        is_deleted: true
      },
      {
        headers: {
          Authorization: "Bearer " + this.token
        }
      }
    )
      .then(res => {
        if (res.data.code === 200) {
          const data = [...this.state.data];
          this.setState({ data: data.filter(item => item.id !== id) });
        } else {
          message.warning(1000, res.data.message);
        }
      })
      .catch(err => {
        message.error(1000, err);
      });
  };

  render() {
    return (
      <TempTable
        columns={this.state.columns}
        data={this.state.data}
        footer={this.renderFooter}
        rowKey={record => record.id}
        tableTitle={"YEMEKHANELER"}
      />
    );
  }
}
