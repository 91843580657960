import React, { Component } from "react";
import { Button, Table, Modal } from "antd";
import readXlsxFile from "read-excel-file";
import moment from "moment";
import Axios from "axios";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";
import { API_ADDRESS } from "../config";
import TempTable from "../Component/common/TempTable";
import message from "../helper";

export default class MealListScreen extends Component {
  state = {
    dataSource: [],
    canSave: false,
    columns: [
      {
        width: "20%",
        title: "Tarih",
        dataIndex: "menu_date_format",
        ellipsis: false
      },
      {
        title: "Yemek",
        width: "80%",
        dataIndex: "menu",
        ellipsis: true
      }
    ]
  };
  componentDidMount() {
    let token = SessionStorageDecrypt("user").token;

    Axios.get(API_ADDRESS + "/Food/Menu", {
      headers: { Authorization: "Bearer " + token }
    });
    const input = document.getElementById("input");
    input.addEventListener("change", () => {
      readXlsxFile(input.files[0]).then(rows => {
        let array = [];
        for (let i = 1; i < rows.length; i++) {
          const obj = {
            menu_date_format: moment(rows[i][0]).format("DD.MM.YYYY"),
            menu_date: moment(rows[i][0]).format(),
            menu: rows[i][1],
            id: i
          };
          array.push(obj);
        }
        this.setState({ dataSource: array, canSave: true });
      });
    });
  }

  saveBulkFoods = () => {
    let token = SessionStorageDecrypt("user").token;
    let menus = [];
    this.state.dataSource.map(data => {
      menus.push({
        menu: data.menu,
        menu_date: data.menu_date
      });
    });

    Axios.post(API_ADDRESS + "/Food/Menu/Bulk", menus, {
      headers: {
        Authorization: "Bearer " + token
      }
    }).then(res => {
      if (res.data.code === 200) {
        message.success(1000, "İşlem başarılı");
        this.props.history.push("/main");
      } else {
        message.warning(1000, res.data.message);
      }
    });
    this.setState({ canSave: false });
  };

  render() {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    return (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            size="large"
            type="primary"
            style={{
              width: "20%",
              marginBottom: "10px",
              minWidth: "150px"
            }}
            onClick={() => {
              document.getElementById("input").click();
            }}
          >
            Excel ile yükle
          </Button>

          <Button
            disabled={!this.state.canSave}
            size="large"
            type="primary"
            style={{ width: "20%", marginBottom: "10px", minWidth: "150px" }}
            onClick={this.saveBulkFoods}
          >
            Kaydet
          </Button>
        </div>
        <input style={{ display: "none" }} type="file" id="input" />

        <TempTable
          columns={this.state.columns}
          data={this.state.dataSource}
          footer={this.renderFooter}
          rowKey={record => record.id + "test"}
          tableTitle={"PERSONEL GRUPLARI"}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
