import React, { Component } from "react";
import { Row, Col, Select, Empty, Layout } from "antd";
import { findByLabelText } from "@testing-library/react";
import UserCard from "../Component/UserCard";
import Text from "antd/lib/typography/Text";
import SpendingTable from "../Component/SpendingTable";
import { AxiosGet } from "../helper/Axios";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";
import TempTable from "../Component/common/TempTable";
import Axios from "axios";
import { connect } from "react-redux";

const { Option } = Select;

var children = [];
let user = SessionStorageDecrypt("user");

class WorkerDetailScreen extends Component {
  state = {
    selected: null,
    columns: [
      {
        title: "İşlem Tarihi ",
        dataIndex: "operation_date",
        align: "left",
        width: "20%"
      },
      {
        title: "İşlem Adedi ",
        dataIndex: "operation_credit",
        align: "left",
        width: "20%"
      },
      {
        title: "İşlem Öncesi Kredi Miktarı",
        dataIndex: "credit_before_operation",
        align: "left",
        width: "20%"
      },
      {
        title: "İşlem Sonrası Kredi Miktarı",
        dataIndex: "credit_after_operation",
        align: "left",
        width: "20%"
      },
      {
        title: "Açıklama",
        dataIndex: "location_definition",
        align: "left",
        width: "20%",
        render: (text, record) => {
          return record.location_definition
            ? record.location_definition
            : "Yükleme";
        }
      }
    ],
    data: []
  };

  componentDidMount() {
    AxiosGet("/account").then(res => {
      res.map(user => {
        children.push(
          <Option key={user.user_row_guid} user={user}>
            {user.full_name}
          </Option>
        );
      });
    });
  }
  componentWillUnmount() {
    children = [];
  }
  onChange(value) {
    this.setState({ selected: value });
  }

  renderEmpty() {
    return (
      <Empty
        style={{ marginTop: "35%" }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{
          height: 60
        }}
        description={<span>Yukarıdan ilgili kişiyi seçiniz.</span>}
      />
    );
  }

  renderPersonCard() {
    return (
      <UserCard
        props={{
          name: user.full_name,
          job: user.job_definition,
          department: user.department_definition,
          photo: user.profile_picture
        }}
      />
    );
  }

  render() {
    return (
      <Layout>
        <Row gutter={[20, 20]}>
          <Col
            xs={24}
            sm={24}
            md={10}
            lg={8}
            xl={6}
            style={(styles.searchBox, {})}
          >
            <Text style={styles.textStyle}>KİŞİLERİ SEÇİN</Text>
            <Select
              size="large"
              showSearch
              style={{ width: "100%", marginBottom: "10px" }}
              placeholder="Aramak için tıklayın."
              optionFilterProp="children"
              onChange={this.onChange.bind(this)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {children}
            </Select>
            {this.state.selected === null
              ? this.renderEmpty()
              : this.renderPersonCard()}
          </Col>
          <Col
            xs={24}
            sm={24}
            md={14}
            lg={16}
            xl={18}
            style={{
              height: "60vh"
            }}
          >
            <SpendingTable creditHistory={this.props.creditHistory} />
          </Col>
        </Row>
        ,
      </Layout>
    );
  }
}

const styles = {
  textStyle: {
    fontWeight: "800",
    color: "#578ebe",
    fontSize: "15px",
    marginBottom: "10px"
  },
  searchBox: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    minHeight: "400px",
    height: "100%"
  }
};

const mapStateToProps = ({ Auth }) => {
  const { userData } = Auth;
  return {
    creditHistory: userData.user_credit_history
  };
};

export default connect(mapStateToProps)(WorkerDetailScreen);
