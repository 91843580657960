import React, { Component } from "react";
import Axios from "axios";
import { API_ADDRESS } from "../config";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";
import { Table } from "antd";
import moment from "moment";

export default class ReservedFoodCount extends Component {
  token = SessionStorageDecrypt("user").token;

  state = {
    reservedFoods: [],
    columns: [
      {
        width: "10%",
        title: "Tarih",
        dataIndex: "reserved_date",
        ellipsis: false,
        render: (text, record) => {
          return moment(record.reserved_date).format("DD.MM.YYYY");
        }
      },
      {
        title: "Yemek",
        width: "60%",
        dataIndex: "menu",
        ellipsis: true
      },
      {
        title: "Yemekhane",
        width: "20%",
        dataIndex: "definition",
        ellipsis: true
      },
      {
        title: "Kişi Sayısı",
        width: "10%",
        dataIndex: "count",
        ellipsis: true
      }
    ]
  };

  componentDidMount() {
    Axios.get(API_ADDRESS + "/Food/Menu/Reserved", {
      headers: {
        Authorization: "Bearer " + this.token
      }
    }).then(res => {
      var id = -1;

      this.setState({
        reservedFoods: res.data.data.map(reservedFood => {
          id++;
          return {
            ...reservedFood,
            id
          };
        })
      });
    });
  }
  render() {
    return (
      <div>
        <Table
          locale={{
            emptyText: <span>Veri yok</span>
          }}
          columns={this.state.columns}
          dataSource={this.state.reservedFoods}
          rowKey={record => record.id}
        />
      </div>
    );
  }
}
