import React, { Component } from "react";
import {
  SessionStorageDecrypt,
  SessionStorageEncrypt
} from "../sessionStorageSecurty";
import Axios from "axios";

import queryString from "query-string";
import { API_ADDRESS } from "../config";
import { Result, Spin } from "antd";

export default class ResponsePurchaseScreen extends Component {
  state = {
    responseData: null,
    loadingPage: true
  };
  async componentDidMount() {
    const user = SessionStorageDecrypt("user");
    const id_token = user.token;
    const conversationId = queryString.parse(this.props.location.search)
      .order_id;
    await Axios.post(
      `${API_ADDRESS}/Payment/Check/${conversationId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + id_token
        }
      }
    )
      .then(res => {
        if (res.data.code === 200) {
          Axios.get(API_ADDRESS + "/Account/" + user.user_row_guid, {
            headers: {
              Authorization: "Bearer " + id_token
            }
          }).then(res => {
            if (res.data.code === 200) {
              SessionStorageEncrypt("user", res.data.data);
            }
          });
          SessionStorageEncrypt("PurchaseObject", res.data.data);
          this.setState({
            responseData: res.data.data,
            loadingPage: false
          });
        } else {
          this.setState({
            loadingPage: false
          });
        }
      })
      .catch(err => {
        this.setState({
          loadingPage: false
        });
      });
  }
  componentWillUnmount() {
    sessionStorage.removeItem("PurchaseObject");
  }
  render() {
    const page = this.state.responseData
      ? this.state.responseData.has_paid
      : null;
    const requestCredit = page ? this.state.responseData.requested_credit : 0;
    return (
      <Spin
        spinning={this.state.loadingPage}
        tip="Bankadan Cevap Bekleniyor..."
      >
        <div
          style={{
            display: "flex",
            justifyItems: "center",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center"
          }}
        >
          {page === true ? ( // page === "SUCCESS"
            <Result
              status="success"
              title="Ödeme İşleminiz Başarıyla Tamamlanmıştır."
              subTitle={
                <div>
                  <b>
                    Hesabınıza {" " + requestCredit + " "}
                    adet kredi yüklenmiştir.
                  </b>
                </div>
              }
              /*extra={[
                          <Button href="/mespact" type="primary" key="console">
                            Anasayfaya Dön
                          </Button>,
                          <Button href="/mespact/card" key="buy">
                            Tekrar Satın Al
                          </Button>,
                        ]}*/
            />
          ) : page === false ? (
            <Result
              status="error"
              title="Ödeme İşleminiz Esnasında Hata Oluştu"
              subTitle={
                this.state.responseData
                  ? this.state.responseData.errorMessages
                  : "Hata meydana geldi lütfen çağrı merkezimiz ile iletişime geçiniz."
              }
              /*extra={[
                          <Button href="/mespact" type="primary" key="console">
                            Anasayfaya Dön
                          </Button>,
                          <Button href="/mespact/card" key="buy">
                            Tekrar Satın Al
                          </Button>,
                        ]}*/
            />
          ) : null}
        </div>
      </Spin>
    );
  }
}
