import React, { Component } from "react";
import Iframe from "react-iframe";

export default class PurchaseScreen extends Component {
  componentWillUnmount() {
    this.props.history.push("/Payment/Check");
    document.getElementById("x").style.display = "none";
  }
  render() {
    return (
      <iframe
        title={"Ödeme"}
        srcDoc={this.props.location ? this.props.location.data : null}
        id="x"
        width="100%"
        style={{ height: "100vh" }}
      />
    );
  }
}
