import React, { Component } from 'react'
import { Table } from 'antd'
import { SessionStorageDecrypt } from '../sessionStorageSecurty';
import moment from 'moment';

export default class PurchaseHistory extends Component {
    state = {
        dataSource: [],
        canSave: false,
        columns: [
            {
                width: "20%",
                title: "Satın Alma Tarih",
                dataIndex: "row_update_date",
                ellipsis: false,
                render: (text, record) => {
                    return moment(record.reserved_date).format("DD.MM.YYYY  HH:mm")
                }
            },
            {
                title: "Satın Alınan Yemek Adedi",
                width: "40%",
                dataIndex: "requested_credit",
                ellipsis: true
            },
            {
                title: "Ödenen Tutar",
                width: "40%",
                dataIndex: "payment_amount",
                ellipsis: true,
                render: (text, record) => {
                    return record.payment_amount+" ₺"
                }
            }
        ]
    };
    componentDidMount() {
        this.setState({
            dataSource: SessionStorageDecrypt("user").user_payment_history
        })
    }
    render() {
        return (
            <div>
                <Table
                    columns={this.state.columns}
                    dataSource={this.state.dataSource}
                    rowKey={record => record.row_guid}
                />
            </div>
        )
    }
}
