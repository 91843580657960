import React, { Component } from "react";
import {
  Button,
  Table,
  Form,
  Input,
  Popconfirm,
  Modal,
  TimePicker,
  message,
  Spin
} from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import Axios from "axios";
import { API_ADDRESS } from "../config";
import {
  SessionStorageEncrypt,
  SessionStorageDecrypt
} from "../sessionStorageSecurty";

const EditableContext = React.createContext();
const format = "HH:mm";

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`
            }
          ],
          initialValue: record[dataIndex]
        })(
          <Input
            ref={node => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };
  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class AddProfileScreen extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "Profil Adı",
        dataIndex: "definition"
      },
      {
        width: "30%",
        title: "İşlemler",
        dataIndex: "operation",
        render: (text, record) => {
          return this.state.dataSource.length >= 1 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Popconfirm
                title="Silmek istediğinize eminmisiniz?"
                onConfirm={() => this.handleDelete(record.key)}
              >
                <a>Sil</a>
              </Popconfirm>
              <a
                onClick={() => {
                  this.forceUpdate();
                  this.setState({
                    profileKey: record.key,
                    modal_visible: true,
                    modal_edit: false,
                    profileName: record.definition,
                    profileTimes: record.times
                  });
                }}
              >
                Detayları Göster
              </a>
            </div>
          ) : null;
        }
      }
    ];

    this.state = {
      loading: true,
      dataSource: [],
      count: 0,
      modal_visible: false,
      modal_edit: false,
      daysWbegin: {
        Pazartesi: moment("00:00:00", "HH:mm:ss"),
        Salı: moment("00:00:00", "HH:mm:ss"),
        Çarşamba: moment("00:00:00", "HH:mm:ss"),
        Perşembe: moment("00:00:00", "HH:mm:ss"),
        Cuma: moment("00:00:00", "HH:mm:ss"),
        Cumartesi: moment("00:00:00", "HH:mm:ss"),
        Pazar: moment("00:00:00", "HH:mm:ss")
      },
      daysWend: {
        Pazartesi: moment("00:00:00", "HH:mm:ss"),
        Salı: moment("00:00:00", "HH:mm:ss"),
        Çarşamba: moment("00:00:00", "HH:mm:ss"),
        Perşembe: moment("00:00:00", "HH:mm:ss"),
        Cuma: moment("00:00:00", "HH:mm:ss"),
        Cumartesi: moment("00:00:00", "HH:mm:ss"),
        Pazar: moment("00:00:00", "HH:mm:ss")
      }
    };
  }
  token = SessionStorageDecrypt("user")
    ? SessionStorageDecrypt("user").token
    : null;
  componentDidMount() {
    Axios.get(API_ADDRESS + "/Profile", {
      headers: {
        Authorization: "Bearer " + this.token
      }
    })
      .then(res => {
        this.setState({ loading: false });
        if (res.data.code == 200) {
          res.data.data.map(profile => {
            const newData = {
              times: profile.profile_times,
              definition: profile.definition,
              key: profile.row_guid
            };
            this.setState({
              dataSource: [...this.state.dataSource, newData]
            });
          });
        } else if (res.data.code == -1) {
          message.warning("Veri yok.");
        } else {
          message.error("Bir hata oluştu.");
        }
      })
      .catch(err => {
        this.setState({ loading: false });
      });
  }

  handleDelete = key => {
    Axios.put(
      API_ADDRESS + "/Profile/Delete/" + key,
      {},
      {
        headers: {
          Authorization: "Bearer " + this.token
        }
      }
    )
      .then(res => {
        if (res.data.code == 200) {
          const dataSource = [...this.state.dataSource];
          this.setState({
            dataSource: dataSource.filter(item => item.key !== key)
          });
        } else {
          message.error("Bir hata oluştu.");
        }
      })
      .catch(err => {});
  };

  handleAdd = () => {
    this.setState({
      profileTimes: undefined,
      daysWbegin: {
        Pazartesi: moment("00:00:00", "HH:mm:ss"),
        Salı: moment("00:00:00", "HH:mm:ss"),
        Çarşamba: moment("00:00:00", "HH:mm:ss"),
        Perşembe: moment("00:00:00", "HH:mm:ss"),
        Cuma: moment("00:00:00", "HH:mm:ss"),
        Cumartesi: moment("00:00:00", "HH:mm:ss"),
        Pazar: moment("00:00:00", "HH:mm:ss")
      },
      daysWend: {
        Pazartesi: moment("00:00:00", "HH:mm:ss"),
        Salı: moment("00:00:00", "HH:mm:ss"),
        Çarşamba: moment("00:00:00", "HH:mm:ss"),
        Perşembe: moment("00:00:00", "HH:mm:ss"),
        Cuma: moment("00:00:00", "HH:mm:ss"),
        Cumartesi: moment("00:00:00", "HH:mm:ss"),
        Pazar: moment("00:00:00", "HH:mm:ss")
      }
    });
    this.forceUpdate();
    this.setState({
      profileName: "",
      modal_edit: true,
      modal_visible: true
    });
  };

  renderDays = () => {
    const days = [
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
      "Pazar"
    ];

    const daysValues = {
      Pazartesi: 0,
      Salı: 1,
      Çarşamba: 2,
      Perşembe: 3,
      Cuma: 4,
      Cumartesi: 5,
      Pazar: 6
    };

    return days.map(day => {
      if (this.state.profileTimes !== undefined) {
        this.bas = moment(
          this.state.profileTimes[daysValues[day]].start_hour +
            ":" +
            this.state.profileTimes[daysValues[day]].start_minute +
            ":00",
          "HH:mm:ss"
        );
        this.bit = moment(
          this.state.profileTimes[daysValues[day]].end_hour +
            ":" +
            this.state.profileTimes[daysValues[day]].end_minute +
            ":00",
          "HH:mm:ss"
        );
        let dwef = this.state.daysWend;
        let dwbf = this.state.daysWbegin;
        let dwe = this.state.daysWend;
        dwe[day] = this.bit;
        let dwb = this.state.daysWbegin;
        dwb[day] = this.bas;
        if (dwb !== dwbf || dwe !== dwef) {
          this.setState({
            daysWend: dwe,
            daysWbegin: dwb
          });
        }
      }

      return (
        <div
          key={day}
          style={{
            marginBottom: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          {day}
          <div
            style={{
              width: "60%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
          >
            Başlangıç Zamanı{" "}
            <TimePicker
              onChange={e => {
                this.setState({
                  profileTimes: undefined
                });
                let dwb = this.state.daysWbegin;
                dwb[day] = e;
                this.setState({
                  daysWbegin: dwb
                });
              }}
              size="large"
              style={{ marginRight: "10px", marginLeft: "10px", width: "30%" }}
              value={moment(this.state.daysWbegin[day], "HH:mm:ss")}
              format={format}
            />
            Bitiş Zamanı
            <TimePicker
              onChange={e => {
                this.setState({
                  profileTimes: undefined
                });
                let dwe = this.state.daysWend;
                dwe[day] = e;
                this.setState({
                  daysWend: dwe
                });
              }}
              size="large"
              style={{ marginLeft: "10px", width: "30%" }}
              value={moment(this.state.daysWend[day], "HH:mm:ss")}
              format={format}
            />
          </div>
        </div>
      );
    });
  };

  handleModalConfirm = () => {
    const { count, dataSource, profileName, profileKey } = this.state;
    let times = [];
    const deys = {
      Pazartesi: 1,
      Salı: 2,
      Çarşamba: 3,
      Perşembe: 4,
      Cuma: 5,
      Cumartesi: 6,
      Pazar: 7
    };

    const days = Object.keys(deys);
    days.map(day => {
      let obj = {
        week_day: deys[day],
        start_hour: this.state.daysWbegin[day].format("HH:mm").slice(0, 2),
        start_minute: this.state.daysWbegin[day].format("HH:mm").slice(3),
        end_hour: this.state.daysWend[day].format("HH:mm").slice(0, 2),
        end_minute: this.state.daysWend[day].format("HH:mm").slice(3)
      };
      times.push(obj);
    });

    if (!this.state.modal_edit) {
      Axios.put(
        API_ADDRESS + "/Profile/" + profileKey,
        {
          definition: profileName,
          profile_times: times
        },
        {
          headers: {
            Authorization: "Bearer " + this.token
          }
        }
      )
        .then(res => {
          if (res.data.code == 200) {
            this.state.modal_visible = false;
          } else {
            message.error("Bir hata oluştu.");
          }
        })
        .catch(err => {});
    } else {
      Axios.post(
        API_ADDRESS + "/Profile",
        {
          definition: profileName,
          profile_times: times
        },
        {
          headers: {
            Authorization: "Bearer " + this.token
          }
        }
      )
        .then(res => {
          if (res.data.code === 200) {
            const newData = {
              key: res.data.data.row_guid,
              definition: profileName
            };
            this.setState({
              dataSource: [...dataSource, newData],
              count: count + 1,
              profileName: "",
              modal_visible: false
            });
          } else {
            message.error("Bir hata oluştu.");
          }
        })
        .catch(err => {});
    }
  };

  handleModalCancel = () => {
    this.setState({ modal_visible: false });
  };

  handleSave = row => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    this.setState({ dataSource: newData });
  };
  render() {
    const { dataSource } = this.state;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave
        })
      };
    });

    return (
      <Spin spinning={this.state.loading}>
        <div
          className="MainContainerDiv"
          style={{
            justifyContent: "center"
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between"
            }}
          >
            <Button
              size="large"
              onClick={this.handleAdd}
              type="primary"
              style={{ marginBottom: 16, width: "20%" }}
            >
              Geçiş Profili Ekle
            </Button>
          </div>
          <Table
            locale={{
              emptyText: <span>Veri yok</span>
            }}
            components={components}
            rowClassName={() => "editable-row"}
            bordered
            dataSource={dataSource}
            columns={columns}
          />{" "}
          <Modal
            width="800px"
            title="Profil ekleme ekranı"
            visible={this.state.modal_visible}
            onOk={this.handleModalConfirm}
            onCancel={this.handleModalCancel}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h3
                style={{
                  width: "20%",
                  paddingTop: "8px"
                }}
              >
                Profil Adı
              </h3>
              <Input
                size="large"
                style={{ marginBottom: "5px" }}
                value={this.state.profileName}
                onChange={e => {
                  this.setState({ profileName: e.target.value });
                }}
              />
            </div>
            {this.renderDays()}
          </Modal>
        </div>
      </Spin>
    );
  }
}

export default AddProfileScreen;
