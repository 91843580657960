import React, { Component } from "react";
import { Table, PageHeader, Button, Icon, Modal, Spin } from "antd";
import { connect } from "react-redux";
import Axios from "axios";
import { API_ADDRESS } from "../config";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";
import PurchaseScreen from "./PurchaseScreen";
import authActions from "../actions/authAction";
import message from "../helper";
import moment from "moment";
import { AxiosGet } from "../helper/Axios";

const { updateProps } = authActions;

class AddCredit extends Component {
  state = {
    selectedRowKeys: [],
    step: 0,
    loading: false,
    reservedFood: [],
    foodData: [],
    purchaseHTML: "",
    columns: [
      {
        title: "Tarih",
        dataIndex: "menu_date",
        width: "20%",
        align: "left",
        render: (text, record) => {
          return moment(record.menu_date).format("DD.MM.YYYY");
        }
      },
      {
        title: "Yemek",
        dataIndex: "menu",
        width: "55%",
        align: "left"
      },
      {
        title: "Açıklama",
        width: "25%",
        align: "left",
        render: (text, record) => {
          return this.state.reservedFood
            .map(food => {
              return food.reserved_date === record.menu_date ? true : false;
            })
            .find(x => x === true) ? (
            <p>Daha önce alındı.</p>
          ) : (
            <p>Satın alınabilir.</p>
          );
        }
      }
    ],
    paymentVisible: false
    // Check here to configure the default column
  };

  token = SessionStorageDecrypt("user").token;

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  componentDidMount() {
    if (SessionStorageDecrypt("user").card_uid === "") {
      Modal.error({
        title: "Lütfen kart numaranızı sisteme tanıttırınız",
        onOk: () => {
          this.props.history.push("/main");
        }
      });
    }
    this.setState({
      reservedFood: this.props.reservedFood
    });
    Axios.get(API_ADDRESS + "/Food/Menu/Purchase", {
      headers: {
        Authorization: "Bearer " + this.token
      }
    }).then(res => {
      const { data, code, message } = res.data;
      if (code === 200) {
        this.setState({
          foodData: data
        });
      }
    }); /*.then(() => {
      this.setState({
        selectedRowKeys: this.state.reservedFood.map(reserved=>{
          return reserved.reserved_date
        })
      })
    });*/
  }

  PurchaseScreen = () => {
    return (
      <div dangerouslySetInnerHTML={{ __html: this.state.purchaseHTML }}></div>
    );
  };

  reserveFood() {
    this.setState({
      loading: true
    });
    Axios.post(
      API_ADDRESS + "/Food/ReserveFood",
      {
        user_row_guid: this.props.userRowGuid,
        reserved_dates: this.state.selectedRowKeys
      },
      {
        headers: {
          Authorization: "Bearer " + this.token
        }
      }
    )
      .then(res => {
        const { data, code } = res.data;
        if (code === 200) {
          message.success(1500, "Yemek rezervasyonu başarılı.");
          this.props.updateProps(res.data.data);
          this.props.history.push("/main");
        } else if (code === 201) {
          if (res.data.message) {
            message.successb(res.data.message);
          }
          this.setState({
            purchaseHTML: data
          });
        } else {
          message.warning(1500, res.data.message);
          AxiosGet("/account/" + this.props.userRowGuid).then(res => {
            this.props.updateProps(res);
            setTimeout(function() {
              window.location.reload();
            }, 2000);
          });
        }
        this.setState({
          loading: false
        });
        return res.data.code;
      })
      .then(code => {
        if (code === 201) {
          this.showModal();
        }
      })
      .catch(err => {
        message.error();
        this.setState({
          loading: false
        });
      });
  }

  showModal = () => {
    this.setState({
      paymentVisible: true
    });
  };

  handleCancel = e => {
    const purchaseSuccess = SessionStorageDecrypt("PurchaseObject")
      ? SessionStorageDecrypt("PurchaseObject").has_paid
      : null;
    const user = SessionStorageDecrypt("user");
    //if (purchaseSuccess === true) {
    this.props.history.push("/reservation");
    Axios.get(API_ADDRESS + "/Account/" + user.user_row_guid, {
      headers: {
        Authorization: "Beraer " + user.token
      }
    }).then(res => {
      this.props.updateProps(res.data.data);
    });
    /*}
    Axios.get(API_ADDRESS + "/Account/" + user.user_row_guid, {
      headers: {
        Authorization: "Bearer " + user.token
      }
    }).then(res => {
      this.props.updateProps(res.data.data);
    });
    this.setState({
      paymentVisible: false,
      loading: false
    });*/
  };

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        {
          key: "all-data",
          text: "Tüm veriyi seç.",
          onSelect: () => {
            this.setState({
              selectedRowKeys: [...this.state.foodData.keys()] // 0...45
            });
          }
        }
      ],
      getCheckboxProps: record => ({
        disabled: this.state.reservedFood
          .map(food => {
            return food.reserved_date === record.menu_date ? true : false;
          })
          .find(x => x === true), // Column configuration not to be checked
        menu_date: record.menu_date
      }),

      onSelectAll: allSelected => {
        if (!this.state.selectedRowKeys.length && allSelected) {
          const deneme = this.state.foodData
            .filter(food => {
              var a = this.state.reservedFood.find(
                item => item.reserved_date === food.menu_date
              );
              return a ? null : food.menu_date;
            })
            .map(last => {
              return last.menu_date;
            });
          this.setState({
            selectedRowKeys: deneme
          });
        } else {
          this.setState({
            selectedRowKeys: []
          });
        }
      }
    };
    const user = SessionStorageDecrypt("user");
    let remaining =
      user.current_credit - this.state.selectedRowKeys.length >= 0
        ? user.current_credit - this.state.selectedRowKeys.length
        : 0;
    let goingtobuy =
      user.current_credit - this.state.selectedRowKeys.length <= 0
        ? this.state.selectedRowKeys.length - user.current_credit
        : 0;

    return (
      <Spin spinning={this.state.loading}>
        <div>
          <h1
            style={{
              fontFamily: "Avenir",
              textAlign: "left",
              alignContent: "center"
            }}
          >
            Mevcut Kredi: {remaining}
            <br /> Satın Alınacak Kredi: {goingtobuy}
          </h1>
          <b
            style={{
              fontFamily: "Avenir",
              textAlign: "left",
              color: "rgb(227, 10, 23)",
              alignContent: "center",
              fontSize: 20
            }}
          >
            {" "}
            Bir sonraki gün için rezervasyon en geç 16:45' da yapılabilmektedir.
          </b>

          <Table
            locale={{
              emptyText: <span>Veri yok</span>
            }}
            style={style.maximizeScreen}
            rowSelection={rowSelection}
            columns={this.state.columns}
            rowKey={rowKey => rowKey.menu_date}
            dataSource={this.state.foodData}
          />
        </div>
        <Modal
          footer={[
            <Button
              style={{
                display: "flex",
                justifyContent: "center"
              }}
              key="back"
              onClick={this.handleCancel}
            >
              İptal
            </Button>
          ]}
          centered
          width={"100%"}
          style={{ height: "90vh" }}
          title="Ödeme Ekranı"
          bodyStyle={{ height: "80vh" }}
          onCancel={this.handleCancel}
          visible={this.state.paymentVisible}
        >
          <iframe
            title={"Ödeme"}
            srcDoc={this.state.purchaseHTML ? this.state.purchaseHTML : null}
            id="x"
            width="100%"
            height="100%"
            style={{ margin: "0 auto", padding: "0 auto", height: "100%" }}
          />
        </Modal>
        <div style={style.buttonRow}>
          <Button
            disabled={
              this.state.selectedRowKeys.length === 0 || this.state.loading
            }
            size="large"
            style={{ minWidth: "200px", width: "15%" }}
            onClick={() => {
              this.reserveFood();
            }}
          >
            Hemen Öde
          </Button>
        </div>
      </Spin>
    );
  }
}

const style = {
  buttonRow: {
    display: "flex",
    width: "100%",
    justifyContent: "center"
  },
  maximizeScreen: {
    background: "white",
    width: "100%",
    height: "100%"
  }
};

const mapStateToProps = ({ Auth }) => {
  const { user_reserved_food_days, user_row_guid } = Auth.userData;
  return {
    reservedFood: user_reserved_food_days,
    userRowGuid: user_row_guid
  };
};
export default connect(mapStateToProps, { updateProps })(AddCredit);
