import { API_ADDRESS } from "../config";
import { Modal } from "antd";
import {
  SessionStorageEncrypt,
  SessionStorageDecrypt
} from "../sessionStorageSecurty";
import md5 from "md5";
import Axios from "axios";

const authActions = {
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  UPDATE_PROPS: "UPDATE_PROPS",
  CHECK_AUTHORIZATION: "CHECK_AUTHORIZATION",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  login: (identity_number, password) => {
    return dispatch => {
      dispatch({ type: authActions.LOGIN });
      const passwordMd5 = md5(password);
      Axios.post(API_ADDRESS + "/Account/Validate", {
        username: identity_number,
        password: passwordMd5
      })
        .then(async res => {
          const { message, data, code } = res.data;
          if (code === 200) {
            // if (res.data.data.role === 8) {
            authActions.loginUserSuccess(dispatch, data);
            /*} else {
              Modal.warning({
                content:
                  "Yeni kartlı geçiş sistemine geçiş aşamasındaki sorunlardan etkilenmemeniz için sistem geçici olarak kapatılmıştır.21.02.2020 ( Cuma ) günü kartınızı okutarak yemeğinizi yiyebilirsiniz.Anlayışınız için teşekkürler."
              });
            }*/
          } else {
            
            Modal.warning({
              content: message
            });
            authActions.loginError(dispatch);
          }
        })
        .catch(err => {
          authActions.loginError(dispatch);
        });
    };
  },
  loginError: dispatch => {
    dispatch({ type: authActions.LOGIN_ERROR });
  },
  loginUserSuccess: (dispatch, user) => {
    SessionStorageEncrypt("user", user);
    dispatch({
      type: authActions.LOGIN_USER_SUCCESS,
      payload: user
    });
  },
  logout: () => {
    return dispatch => {
      sessionStorage.removeItem("user");
      dispatch({ type: authActions.LOGOUT });
    };
  },
  updateProps: data => {
    return dispatch => {
      SessionStorageEncrypt("user", data);
      dispatch({ type: authActions.UPDATE_PROPS, payload: data });
    };
  },
  checkAuthorization: () => {
    return dispatch => {
      dispatch({ type: authActions.CHECK_AUTHORIZATION });
      const user = SessionStorageDecrypt("user");
      const isValidate = sessionStorage.hasOwnProperty("user");
      if (isValidate) {
        authActions.loginUserSuccess(dispatch, user);
        /*Axios.get(API_ADDRESS + "/Account/" + user.user_row_guid, {
          headers: {
            Authorization: "Bearer " + user.token
          }
        })
          .then(res => {
            console.log(res);
            if (res.data.code === 200) {
              SessionStorageEncrypt("user", res.data.data)
              authActions.loginUserSuccess(dispatch, res.data.data);
            }
          })
        */
      }
    };
  }
};

/*
export const loginUser = ({ userName, password, token }) => {
    return (dispatch) => {
        dispatch({ type: LOGIN_USER });
 
        const passwordMd5 = md5(password);
        const params = {
            Password: passwordMd5,
            UserName: userName
        };
        const header = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        axios.post(`${API_ADDRESS}/User/Validate`,
            params,
            header
        )
            .then(user => {
                console.log(user.data);
                if (user.data.code === 200) {
                    const userData = user.data.data;
                    console.log(userData.rowGuid);
                    saveUser(userName, password, token, userData.rowGuid);
                    console.log('store =>', AsyncStorage.getItem('user'));
                    loginUserSuccess(dispatch, userData);
                } else {
                    loginUserFail(dispatch);
                    Actions.login();
                }
            })
            .catch((as) => console.log(`hata =>${as}`));
    };
};
 
export const getToken = () => {
    return (dispatch) => {
        axios.post(`${API_ADDRESS}/GetToken`, {
            ApiKey: 'CFA37D531EF28616BF80261A0AFD1467BB493123VNSDA5158898A'
 
        }).then(res => {
            console.log(res.data);
            dispatch({ type: GET_TOKEN, payload: res.data.token });
            return res.data.token;
        });
    };
};
 
const saveUser = (userName, password, token, rowGuid) => {
    const obj = {
        userName,
        password,
        token,
        rowGuid,
    };
    AsyncStorage.setItem('user', JSON.stringify(obj));
};
 
const loginUserFail = (dispatch) => {
    dispatch({ type: LOGIN_USER_FAIL });
};
 
const loginUserSuccess = (dispatch, user) => {
    dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: user
    });
    Actions.main();
};
 
export const isValidated = () => {
    return (dispatch) => {
        AsyncStorage.getItem('user').then(user => {
            if (user) {
                const { userName, password, token } = JSON.parse(user);
                dispatch(
                    loginUser({ userName, password, token })
                );
            } else {
                Actions.login();
            }
        });
    };
};
*/
/*
export const logOut = () => {
    return (dispatch) => {
        dispatch({ type: 'log_out' });
        sessionStorage.removeItem('user')
        })
    };
};*/
export default authActions;
