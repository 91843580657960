import React, { Component } from "react";
import Axios from "axios";
import { API_ADDRESS } from "../config";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";
import { Table, Button, Icon, Input } from "antd";
import moment from "moment";
import Highlighter from "react-highlight-words";

export default class MenuScreen extends Component {
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              this.handleSearch(selectedKeys, confirm, dataIndex)
            }
            style={{
              width: 188,
              marginBottom: 8,
              display: "block"
            }}
          />
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Ara
        </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Sıfırla
        </Button>
        </div>
      ),

    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  state = {
    searchText: "",
    searchedColumn: ""
  };

  token = SessionStorageDecrypt("user").token;

  state = {
    foodMenu: [],
    columns: [
      {
        width: "20%",
        title: "Tarih",
        dataIndex: "menu_date",
        ...this.getColumnSearchProps("menu_date"),

        ellipsis: false,
        render: (text, record) => {
          return moment(record.menu_date).format("DD.MM.YYYY");
        }
      },
      {
        ...this.getColumnSearchProps("menu"),

        title: "Yemek",
        width: "80%",
        dataIndex: "menu",
        ellipsis: true
      }
    ]
  };
  componentDidMount() {
    Axios.get(API_ADDRESS + "/Food/Menu/", {
      headers: {
        Authorization: "Bearer " + this.token
      }
    }).then(res => {
      this.setState({
        foodMenu: res.data.data
      });
    });
  }
  render() {
    return (
      <div>
        <Table
          locale={{
            emptyText: <span>Veri yok</span>
          }}
          columns={this.state.columns}
          dataSource={this.state.foodMenu}
          rowKey={record => record.id}
        />
      </div>
    );
  }
}
