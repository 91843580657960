import Axios from "axios";
import { API_ADDRESS } from "../config";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";

const AxiosGet = async endpoint => {
  let token = await SessionStorageDecrypt("user").token;
  return new Promise((resolve, reject) => {
    Axios.get(API_ADDRESS + endpoint, {
      headers: { Authorization: "Bearer " + token }
    })
      .then(res => {
        if (res.data.code === 200) {
          resolve(res.data.data);
        } else {
          reject();
        }
      })
      .catch(err => {
      });
  });
};

const AxiosPost = async (endpoint, data) => {
  let token = await SessionStorageDecrypt("user").token;
  return new Promise((resolve, reject) => {
    Axios.put(API_ADDRESS + endpoint, data ? data : {}, {
      headers: { Authorization: "Bearer " + token }
    })
      .then(res => {
        if (res.data.code === 200) {
          resolve(res.data.data);
        } else {
          reject();
        }
      })
      .catch(err => {});
  });
};

export { AxiosGet, AxiosPost };
