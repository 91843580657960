import React, { Component } from "react";
import { Table, Button, Icon, Input } from "antd";
import Text from "antd/lib/typography/Text";
import TempTable from "./common/TempTable";
import moment from "moment";
import Highlighter from "react-highlight-words";


export default class SpendingTable extends Component {
  state = {
    searchText: "",
    searchedColumn: ""
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            width: 188,
            marginBottom: 8,
            display: "block"
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Ara
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Sıfırla
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  state = {
    columns: [
      {
        title: "İşlem Tarihi ",
        dataIndex: "operation_date",
        align: "left",
        width: "20%",
        ...this.getColumnSearchProps("operation_date"),
        sorter: (a, b) =>
          moment(a.operation_date).unix() - moment(b.operation_date).unix(),
        render: (text, record) => {
          moment.locale("tr");
          return moment(record.operation_date).format("DD.MM.YYYY HH:mm");
        }
      },
      {
        title: "İşlem Adedi ",
        dataIndex: "operation_credit",
        align: "left",
        width: "20%"
      },
      {
        title: "İşlem Öncesi Kredi Miktarı",
        dataIndex: "credit_before_operation",
        align: "left",
        width: "20%"
      },
      {
        title: "İşlem Sonrası Kredi Miktarı",
        dataIndex: "credit_after_operation",
        align: "left",
        width: "20%"
      },
      {
        title: "Açıklama",
        dataIndex: "operation_type_definition",
        align: "left",
        width: "20%",
      }
    ],
    data: []
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  render() {
    return (
      <div
        style={{
          paddingTop: "12px",
          paddingRight: "20px",
          paddingBottom: "15px",
          paddingLeft: "20px",
          backgroundColor: "#fff",
          width: "100%",
          height: "100%"
        }}
      >
        <Text
          style={{
            fontWeight: 800,
            color: "#578ebe",
            fontSize: "15px",
            marginBottom: "30px"
          }}
        >
          HARCAMA VE YÜKLEME BİLGİLERİ
        </Text>
        <TempTable
          pageSize={this.props.pageSize}
          size={"default"}
          columns={this.state.columns}
          data={this.props.creditHistory}
          rowKey={record => record.id}
          style={{ height: "100%", width: "100%" }}
        />
      </div>
    );
  }
}
