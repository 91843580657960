import React, { Component } from "react";
import TempTable from "../Component/common/TempTable";
import message from "../helper";
import Axios from "axios";
import { API_ADDRESS } from "../config";
import { Input, Button } from "antd";
import { SessionStorageDecrypt } from "../sessionStorageSecurty";

export default class Office extends Component {
  token = SessionStorageDecrypt("user").token;
  state = {
    columns: [
      {
        title: "DAİRELER",
        dataIndex: "definition",
        width: "90%"
      },
      {
        title: "İşlemler",
        key: "action",
        align: "right",
        width: "10%",
        render: (text, record) => {
          return (
            <Button
              onClick={() => {
                this.deleteOffice(record.row_guid, record.id);
              }}
              style={{ width: "100%" }}
            >
              Sil
            </Button>
          );
        }
      }
    ],
    data: [],
    office_definition: ""
  };
  componentDidMount() {
    Axios.get(API_ADDRESS + "/office", {
      headers: {
        Authorization: "Bearer " + this.token
      }
    }).then(res => {
      this.setState({
        data: res.data.data.filter(data => (data.is_active = true))
      });
    });
  }

  renderFooter = () => {
    return (
      <div style={{ display: "flex", flex: 1, alignContent: "center" }}>
        <Input
          placeholder="Office adı giriniz ..."
          value={this.state.office_definition}
          onChange={e => this.setState({ office_definition: e.target.value })}
          style={{ width: "90%", marginRight: "10px" }}
        />
        <div
          style={{
            width: "10%",
            display: "flex",
            flex: 1,
            justifyContent: "flex-end"
          }}
        >
          <Button
            type="primary"
            onClick={this.insertOffice}
            style={{ align: "right", marginRight: "10px", width: "100%" }}
          >
            Ekle
          </Button>
        </div>
      </div>
    );
  };

  insertOffice = () => {
    const definition = this.state.office_definition
      ? this.state.office_definition
      : null;
    !definition
      ? message.warning(1300, "Doldurmanız gereken alanlar mevcut.")
      : Axios.post(
          API_ADDRESS + "/office",
          {
            definition
          },
          {
            headers: {
              Authorization: "Bearer " + this.token
            }
          }
        )
          .then(res => {
            const { data, code } = res.data;
            if (code) {
              this.setState({
                data: [...this.state.data, data],
                office_definition: ""
              });
              message.success(1000, "Ekleme işlemi başarılı");
            } else {
              message.warning("Kayıt işlemi başarısız");
            }
          })
          .catch(err => {
            message.error("Hata oluştu.");
          });
  };

  deleteOffice = (row_guid, id) => {
    Axios.put(
      API_ADDRESS + "/office",
      {
        row_guid,
        is_deleted: true
      },
      {
        headers: {
          Authorization: "Bearer " + this.token
        }
      }
    )
      .then(res => {
        if (res.data.code === 200) {
          const data = [...this.state.data];
          this.setState({ data: data.filter(item => item.id !== id) });
          message.success(1000, "İşlem başarılı");
        } else {
          message.warning(1000, res.data.message);
        }
      })
      .catch(err => {
        message.error(1000, err);
      });
  };

  render() {
    return (
      <TempTable
        columns={this.state.columns}
        data={this.state.data}
        footer={this.renderFooter}
        rowKey={record => record.id}
        tableTitle={"DAİRELER"}
      />
    );
  }
}
