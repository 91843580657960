import React, { Component } from "react";
import { Card } from "antd";

export default class UserCard extends Component {
  render() {
    return (
      <Card
        style={{ width: "100%", marginBottom: "20px" }}
        cover={
          <img
            alt="profil_photo"
            style={{
              height: "50%",
              borderRadius: "50%",
              marginTop: "10%",
              paddingLeft: "25%",
              paddingRight: "25%",
              objectFit: "cover",
              objectPosition: "50% 0"
            }}
            src={"data:image/jpeg;base64, " + this.props.props.photo}
          />
        }
      >
        <p
          style={{
            textAlign: "center",
            color: "#5a7391",
            fontSize: "20px",
            fontWeight: 600,
            marginBottom: "7px"
          }}
        >
          {this.props.props.name}
        </p>
        <p
          style={{
            textAlign: "center",
            color: "#5b9bd1",
            fontSize: "13px",
            fontWeight: 800,
            marginBottom: "7px",
            textTransform: "uppercase"
          }}
        >
          {this.props.props.job}
        </p>
        <p
          style={{
            textAlign: "center",
            color: "#F3565D",
            fontSize: "13px",
            fontWeight: 800,
            marginBottom: "7px",
            textTransform: "uppercase"
          }}
        >
          {this.props.props.department}
        </p>
      </Card >
    );
  }
}
